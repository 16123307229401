<template>
  <div class="g-safe-area h-full">
    <BossStartFrame
      v-if="showStart"
      :name="name"
      class="h-full"
      @close="onBack"
      @next="showStart = false"
    />

    <BossCard
      v-else
      :face="currentBoss"
      :combo="combo"
      class="h-full"
      @close="onBack"
      @star="onStar"
      @next="onCardNext"
      @done="onDone"
    />
  </div>
</template>

<script setup lang="ts">
import BossCard from '@/components/VirtualCard/Boss.vue'
import {
  ClozeCardFaceType,
  type ClozeCard,
  type ClozeCardFace,
  type VirtualCardBoss,
  VirtualCardType,
} from '@/types/core'
import { parseClozeCard } from '@/utils/card'
import { randomPickBoss } from '@/utils'
import { ref, computed } from 'vue'
import BossStartFrame from '../UnitLearn/BossStartFrame.vue'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import { useRouter } from 'vue-router'

_am.preload([
  { type: 'audio', name: 'public_hit' },
  {
    type: 'audio',
    name: 'compress-monster_cry',
  },
])

const cardContentList = [
  '{"type":"cloze.card","content":[{"type":"p","content":[{"text":"《山海经》是我国目前保存","type":"text"},{"text":"神话资料","type":"cloze","group":"c1"},{"text":"最多的著作","type":"text"}]}],"analysis":[{"type":"p","content":[]}],"altContents":[]}',
  '{"type": "cloze.card", "content": [{"type": "p", "content": [{"text": "《山海经》中的", "type": "text"}, {"text": "矿物记录", "type": "cloze", "group": "c1"}, {"text": "是世界上最早的有关文献。", "type": "text"}]}], "analysis": [{"type": "p", "content": []}], "altContents": []}',
]

const clozeCardFaces: ClozeCardFace[] = cardContentList.map((content, i) => {
  const note = JSON.parse(content) as ClozeCard
  const parsedResult = parseClozeCard(note)
  return {
    type: ClozeCardFaceType.Choice,
    cardId: i,
    card: parsedResult.card,
    isFirstLearn: false,
    altCards: [],
    style: {
      showCardDetail: false,
      hideRoleImage: true,
      operationLayout: 'horizontal',
      hideTip: true,
    },
  }
})

const router = useRouter()

const combo = ref(0)
const faces = ref(clozeCardFaces)
const showStart = ref(true)
const name = randomPickBoss()
const frames = ref(
  clozeCardFaces
    .map((item, i) => {
      return {
        type: VirtualCardType.Boss,
        cardFace: item,
        name,
        hp: ((faces.value.length - i) / faces.value.length) * 100,
        tickHp: Math.ceil((1 / faces.value.length) * 100),
      } as VirtualCardBoss
    })
    .concat({
      type: VirtualCardType.Boss,
      name,
      hp: 0,
      tickHp: Math.ceil((1 / faces.value.length) * 100),
    })
)

const currentBoss = computed(() => frames.value[0])

function onStar(star: FeedbackStar) {
  if (star === FeedbackStar.Three) {
    combo.value += 1
  } else {
    combo.value = 0
  }
}

function onCardNext() {
  frames.value.shift()
}

function onDone() {
  _refreshApp()
}

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.push(_global.homePage)
  }
}
</script>

<style scoped></style>
