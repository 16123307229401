<template>
  <div class="h-full w-full flex flex-col flex-1 overflow-hidden items-center">
    <div
      ref="containerRef"
      class="flex-1 min-h-0 g-body-width overflow-y-auto card-container pt-16px"
      :class="[
        'pb-72px',
        {
          'justify-between': viewSize.width <= 700,
        },
      ]"
    >
      <div class="p-4 pt-40px">
        <div class="relative">
          <RoleImage
            v-if="!hideRoleImage"
            class="mx-auto"
          />
          <div
            class="flex flex-col items-center justify-center question-text-background"
          >
            <slot name="content" />
          </div>
        </div>
      </div>

      <slot name="center"></slot>
      <div class="p-4">
        <slot name="options" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'
import RoleImage from '../common/RoleImage.vue'

defineProps<{
  hideRoleImage: boolean
}>()

const containerRef = ref<HTMLDivElement>()
const viewSize = window._viewSize

provide('cardContainer', containerRef)
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
  /* 这个 relative 是给英语查词的 tooltip 定位使用的 */
  position: relative;
}

.question-text-background {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  padding: 16px;
}
</style>
