import is from 'is_js'
import dayjs from 'dayjs'
import { floor } from 'lodash-es'
import type { UserInfo } from '@/api/auth'
import { type UserVipInfo } from '@/api/user'
import CropperImg from '@/components/CropperImg.vue'
import { parseAiInput } from '@/utils/aiInput'
import nlp from 'compromise'
import { getContentClozes } from './card'
import { VibrateType } from './bridge'
export { VibrateType } from './bridge'

export function isEmail(input: string): boolean {
  // 如果包含中文，返回false
  if (/[^\x00-\xff]/.test(input)) {
    return false
  }
  return is.email(input)
}

export function warn(...args: any[]) {
  console.warn(...args)
}

export function randomString(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length

  // 生成随机字符串
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

// format date in local timezone
export function formatDate(
  input: string | Date | number,
  format: string = 'YYYY-MM-DD HH:mm:ss'
) {
  return dayjs(input).format(format)
}

// e.g. 1 -> A, 2 -> B
// i: starts with 0
export function number2ChoiceOptionText(i: number) {
  // 'A' char code is 65
  return String.fromCharCode(65 + i)
}

export function tryJSONParse(str: string, dft: any = null) {
  try {
    return JSON.parse(str)
  } catch (_err) {
    return dft
  }
}

export function formatDateRelative(input: string | Date | number) {
  const today = dayjs(new Date().setHours(0, 0, 0, 0))

  const diffDays = today.diff(input, 'day', true)
  const diffWeeks = today.diff(input, 'week')
  const diffMonths = today.diff(input, 'month')
  const diffYears = today.diff(input, 'year')

  if (diffYears > 0) {
    return `${diffYears} 年前`
  } else if (diffMonths > 0) {
    return `${diffYears} 月前`
  } else if (diffWeeks > 0) {
    return `${diffYears} 周前`
  } else if (diffDays > 0) {
    return '昨天'
  } else {
    return '今天'
  }
}

export const KB = 1024
export const MB = 1024 * 1024

export function formatSize(bytes: number) {
  if (bytes < MB) {
    const kb = (bytes / KB).toFixed(2)

    return `${kb} KB`
  }

  const mb = (bytes / MB).toFixed(2)

  return `${mb} MB`
}

async function getImageFromFile(file: File): Promise<HTMLImageElement> {
  const url = URL.createObjectURL(file)

  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = function () {
      resolve(img)
      URL.revokeObjectURL(url)
    }

    img.onerror = reject

    img.src = url
  })
}

export async function readImageSize(
  file: File
): Promise<{ height: number; width: number }> {
  const img = await getImageFromFile(file)

  return {
    height: img.height,
    width: img.width,
  }
}

function renameFileExt(filename: string, newExt: string): string {
  const parts = filename.split('.')

  if (parts.length === 1) return filename

  const fileNameWithoutExt = parts.slice(0, -1).join('.')

  return `${fileNameWithoutExt}.${newExt}`
}

const MIN_COMPRESS_SIZE = 500 * KB

export async function resizeAndCompressImage(
  file: File,
  maxWidth: number,
  maxHeight: number,
  quality: number = 0.8
): Promise<File> {
  if (file.size < MIN_COMPRESS_SIZE) return file

  if (file.name.endsWith('.gif')) return file

  const image = await getImageFromFile(file)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!

  // 计算压缩比例
  let width = image.width
  let height = image.height
  const aspectRatio = width / height

  if (width > maxWidth || height > maxHeight) {
    if (aspectRatio > 1) {
      // 宽度较大
      width = maxWidth
      height = Math.floor(maxWidth / aspectRatio)
    } else {
      // 高度较大
      height = maxHeight
      width = Math.floor(maxHeight * aspectRatio)
    }
  }

  // 设置canvas的大小
  canvas.width = width
  canvas.height = height

  // 绘制调整大小后的图片
  ctx.drawImage(image, 0, 0, width, height)

  return canvasToImageFile(canvas, file.name, 'image/jpeg', quality)
}

export function canvasToImageFile(
  canvas: HTMLCanvasElement,
  filename: string,
  type: 'image/jpeg' | 'image/png',
  quality: number
): Promise<File> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(new File([blob], renameFileExt(filename, 'jpg')))
        } else {
          reject(new Error('failed to compress image'))
        }
      },
      type,
      quality
    )
  })
}

export function fenToYuan(fen: number) {
  return floor(fen / 100, 2)
}

export function randomPick<T>(list: T[]): T {
  const index = Math.floor(Math.random() * list.length)
  return list[index]
}

export function setViewportHeight() {
  document.documentElement.style.setProperty(
    '--ld-viewport-height',
    window.innerHeight + 'px'
  )
}

export function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

// 结果集可能包含多单词字符串，例如输入为 `register` 时返回结果中会有 "will register"
function getEnWordCandidates(word: string): string[] {
  const doc = nlp(word)
  const candidates = new Set([word])

  // 动词
  if (doc.verbs().found) {
    const conjugations = doc.verbs().conjugate()[0]
    Object.values(conjugations).forEach(form => candidates.add(form))
  }

  // 名词
  else if (doc.nouns().found) {
    candidates.add(doc.nouns().toSingular().text())
    candidates.add(doc.nouns().toPlural().text())
  }

  // 形容词
  else if (doc.adjectives().found) {
    candidates.add(doc.adjectives().toSuperlative().text())
    candidates.add(doc.adjectives().toComparative().text())
  }

  return [...candidates]
}

// 把一段文本拆分成一个个单词
export function parseTokens(input: string): string[] {
  const words: string[] = []

  let currentWord = ''
  for (let i = 0; i < input.length; i++) {
    const char = input[i]

    // 如果不是个字母，则认为单词结束了
    if (!isChar(char)) {
      if (currentWord !== '') {
        words.push(currentWord)
        currentWord = ''
      }
      words.push(char)
    } else {
      currentWord += char
    }
  }

  if (currentWord !== '') {
    words.push(currentWord)
  }

  return words
}

export function isChar(input: string): boolean {
  return /^[a-zA-z']$/.test(input)
}

function isSingleWord(input: string): boolean {
  return input.split(' ').length === 1
}

export interface HighlightSpan {
  text: string
  highlight: boolean
}

// 将一段文本中的某一个单词或者短语高亮
// 其中单词会进行语义匹配，匹配名词、进行时、过去时等
// 短语则只会进行完全匹配
// 其中所有的匹配都是忽略大小写的
// 支持手动标记高亮部分，语法为 **HIGHLIGHT**
// eg: When shall I do the **shopping** ?
export function getHighlightSpans(
  text: string,
  targetWord: string
): HighlightSpan[] {
  // 先解析手动标记的高亮，如果没有，再用 nlp 解析
  {
    const manualHighlights: HighlightSpan[] = []
    let idx = 0
    for (const m of text.matchAll(/\*\*(.+?)\*\*/g)) {
      const left = text.slice(idx, m.index)

      if (left.length > 0) {
        manualHighlights.push({
          highlight: false,
          text: left,
        })
      }
      manualHighlights.push({
        highlight: true,
        text: m[1],
      })

      idx = m.index! + m[0].length
    }

    if (idx < text.length) {
      manualHighlights.push({
        highlight: false,
        text: text.slice(idx),
      })
    }

    // 如果存在手动标记，则直接返回手动标记的结果
    if (manualHighlights.some(item => item.highlight)) {
      return manualHighlights
    }
  }

  const noHighlights: HighlightSpan[] = [{ text, highlight: false }]
  const isSingle = isSingleWord(targetWord)

  if (isSingle) {
    const candidates = getEnWordCandidates(targetWord)
    const tokens: string[] = parseTokens(text)

    for (const can of candidates) {
      const index = tokens.findIndex(
        token => token.toLowerCase() === can.toLowerCase()
      )

      if (index > -1) {
        return tokens.map((token, i) => {
          return {
            text: token,
            highlight: i === index,
          }
        })
      }
    }

    // 没找到就返回 noHighlights
    return noHighlights
  }

  // 词组，e.g `run out of`
  const idx = text.toLowerCase().indexOf(targetWord.toLowerCase())
  if (idx >= 0) {
    const beforeIdx = idx - 1
    if (beforeIdx >= 0 && isChar(text[beforeIdx])) {
      return noHighlights
    }

    const afterIdx = idx + targetWord.length
    if (afterIdx < text.length && isChar(text[afterIdx])) {
      return noHighlights
    }

    return [
      { text: text.slice(0, idx), highlight: false },
      { text: text.slice(idx, idx + targetWord.length), highlight: true },
      { text: text.slice(idx + targetWord.length), highlight: false },
    ]
  }

  return noHighlights
}

export function pickFile(accept = '*'): Promise<File> {
  return new Promise(resolve => {
    const input = document.createElement('input') as HTMLInputElement
    input.setAttribute('accept', accept)
    input.type = 'file'
    input.style.display = 'none'
    document.body.appendChild(input)
    input.click()
    input.addEventListener('change', evt => {
      const file = (evt.target as HTMLInputElement).files?.[0]

      if (file != null) {
        resolve(file)
      }
    })
  })
}

// 选取图片并裁剪压缩，默认不支持 gif
export async function pickImg(
  title: string,
  gif: boolean = false
): Promise<File | null> {
  let accept = '.jpg,.jpeg,.png,.svg,.bmp,.webp'

  if (gif) {
    accept += ',.gif'
  }

  let file: File | null = null

  if (_global.isInsideApp) {
    const res = await _bridge.requestPhotosPermission()
    if (!res) {
      _confirm({
        scene: 'confirm',
        content: _t('无法使用你的照片\n请在设置中开启「照片」'),
        primaryText: _t('开启权限'),
        secondaryText: _t('暂不'),
        onPrimaryClick(resolve) {
          resolve(true)
          _bridge.openAppSettings()
        },
      })
      return null
    }
    file = await _bridge.pickImage({ camera: false })
  } else {
    file = await pickFile(accept)
  }

  if (file == null) return null

  let imgFile: File | null = null

  const isPng = file.type === 'image/png'
  // gif 跳过裁剪压缩
  if (!file.name.endsWith('.gif')) {
    const src = URL.createObjectURL(file)
    const canvas = await _openDialog<HTMLCanvasElement>(CropperImg, {
      title: title,
      props: {
        src: src,
      },
      rootClass: _global.isPcMode ? 'min-w-600px' : 'w-full',
    })
    URL.revokeObjectURL(src)
    if (canvas) {
      imgFile = await canvasToImageFile(
        canvas,
        file.name,
        isPng ? 'image/png' : 'image/jpeg',
        0.8
      )
    }
  } else {
    imgFile = file
  }

  return imgFile
}

// NOTE(buding): 如果用户手动关闭弹窗，该方法不会返回，永远 pending
export async function loadAliyunCaptcha(
  button: HTMLButtonElement,
  elementId: string,
  onCaptchaVerify: (param: string) => {
    captchaResult: boolean
    bizResult: boolean
  }
) {
  document.getElementById('aliyunCaptcha-mask')?.remove()
  document.getElementById('aliyunCaptcha-window-popup')?.remove()
  const width = Math.min(window.innerWidth * 0.8, 360)

  initAliyunCaptcha({
    SceneId: _global.aliyunCaptchaSceneId,
    prefix: _global.aliyunCaptchaPrefix,
    mode: 'popup',
    button: `#${button.id}`,
    element: `#${elementId}`,
    captchaVerifyCallback: onCaptchaVerify,
    slideStyle: {
      width,
    },
    language: 'cn',
  })
}

// 获取文字行数
export function getTextLineCount(textElement: HTMLElement): number {
  const computedStyle = window.getComputedStyle(textElement)
  const lineHeight = parseInt(computedStyle.lineHeight)
  const height = textElement.clientHeight

  return Math.floor(height / lineHeight)
}

// 判断文本是否被截断
export function isTextTruncated(element: HTMLElement) {
  const height = element.clientHeight
  return element.scrollHeight > height
}

// 传入 seconds
// 转成 mm:ss
// 如果超过 60 分钟，则显示 hh:mm 最多显示到 “99:59”
export function formatLearnDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60
  const sec = seconds % 60

  const secStr = String(sec).padStart(2, '0')
  const minStr = String(minutes).padStart(2, '0')
  const hourStr = String(hours).padStart(2, '0')

  if (hours >= 100) {
    return '99:59'
  }

  if (hours > 0) {
    return `${hourStr}:${minStr}`
  }
  return `${minStr}:${secStr}`
}

export function displayCurrency(currency: 'CNY') {
  const map = {
    CNY: '¥',
  }

  return map[currency] ?? map['CNY']
}

export function clamp(value: number, min: number, max: number) {
  if (value < min) return min
  if (value > max) return max
  return value
}

// Boss 数量 12 个 从 1001 - 1012
// https://qianmojiaoyu.feishu.cn/wiki/GLFZwUHumixOI4kKyuzcaNBQnPb
// https://qianmojiaoyu.feishu.cn/drive/folder/JmMsfvuwWlu9OndAqczcCJlrnVg
export const bossList: {
  name: string
  displayName: string
}[] = [
  {
    name: '1001',
    displayName: '羞羞龙',
  },
  {
    name: '1002',
    displayName: '蝠仔',
  },
  {
    name: '1003',
    displayName: '阿努比喵',
  },
  {
    name: '1004',
    displayName: '暴暴',
  },
  {
    name: '1005',
    displayName: '哆儿',
  },
  {
    name: '1006',
    displayName: '刺巫巫',
  },
  {
    name: '1007',
    displayName: '猪丽叶',
  },
  {
    name: '1008',
    displayName: '胆小狗',
  },
  {
    name: '1009',
    displayName: '哈弟',
  },
  {
    name: '1010',
    displayName: '雪球',
  },
  {
    name: '1011',
    displayName: '毛掸儿',
  },
  {
    name: '1012',
    displayName: '小焰',
  },
]

// 随意挑选一个 boss 形象
export function randomPickBoss(): string {
  return randomPick(bossList).name
}

// 获取 boss 名称
export function getBossDisplayName(bossId: string): string {
  const boss = bossList.find(boss => boss.name === bossId)
  return boss?.displayName ?? ''
}

// 获取 boss 半身形象
export function getBossHalf(bossId: string): string {
  return `${bossId}_half`
}

// 获取 boss 头像
export function getBossAvatar(bossId: string): string {
  return `${bossId}_avatar`
}

// 打开兔小巢
function openTxc(
  productId: number,
  data?: {
    nickname: string
    openid: string
    avatar: string
    customInfo?: string
  }
) {
  let url = `${window.location.origin}/support/${productId}`
  if (data) {
    url += '?' + new URLSearchParams(data).toString()
  }
  openLink(url)
}

// 打开链接 在 app 内打开浏览器，在网页内直接跳转
export function openLink(url: string) {
  if (_global.isInsideApp) {
    _bridge.launch(url)
  } else {
    window.open(url)
  }
}

// 打开隐私政策
export function openPrivacyPolicy() {
  openLink(_global.privacyLink)
}
// 打开用户协议
export function openUserAgreement() {
  openLink(_global.userLink)
}
// 打开联系我们
export function openContactUs() {
  openLink(_global.contactLink)
}

export function openHelpLink(user: UserInfo | null, vip: UserVipInfo | null) {
  if (!user) {
    openTxc(_global.txcProductId)
    return
  }

  const nickname = user.nickname
  const openid = user.id

  let avatar = user.avatar
  if (avatar) {
    if (!avatar.startsWith('http')) {
      avatar = _global.assetUrl(avatar, 'm')
    }
  } else {
    avatar = _global.assetUrl('sys/logo.png', 'm')
  }

  const customInfo = {
    phone: user.phone,
    email: user.email,
    vip: vip,
  }

  openTxc(_global.txcProductId, {
    nickname,
    openid,
    avatar,
    customInfo: JSON.stringify(customInfo),
  })
}

export function genAiSessionId() {
  return Math.random().toString(16).slice(2)
}

export function validateAiKeypoint(keypoint: string) {
  const content = parseAiInput(keypoint)
  const allClozes = getContentClozes(content)

  // ai 生成的知识点不能为空且至少包含一个挖空
  return keypoint.trim() !== '' && allClozes.length > 0
}

// Boss 阶段结束界面文案 https://qianmojiaoyu.feishu.cn/wiki/GGbjwvmHoijcXlkNW2PcsGZ0nVe

// Boss 气泡框文案
export function getBossBubbleText(): string {
  const list: string[] = [
    '居然…被你打败了',
    '救命！居然打不过！',
    '啊这…我输了？',
    '这次算你赢了！',
    '我竟败在你手上？！',
    '怎么可能……',
    '真…真不甘心…',
    '是我大意了！',
    '我还会回来的！',
    '下次…我一定会赢的！',
    '后会有期！',
    '这次算你走运！',
    '我记住你了！',
    '居然输了…不可能！',
    '今天状态不好…',
    '真有你的，下次再战！',
    '算你厉害……',
    '可恶…竟然…',
    '这…这不可能！',
    '你居然赢了…等着瞧吧！',
    '吾之复仇…才刚刚开始…',
    '好吧好吧，你赢了~',
    '没想到…翻车了…',
    '打扰了，告辞！',
    '是我低估你了…',
    '今天不宜战斗…',
    '我…我还能抢救一下吗？',
    '佩服！不愧是高手！',
    '这不可能…我怎么会输…',
    '今天…就先放过你…',
    '这…这只是意外！',
    '不可能…我竟然…',
    '我…我尽力了…',
    '大意了，没有闪…',
    '是我低估你了…',
    '下次一定雪耻！',
    '啊这…居然翻车了？',
    '就…挺突然的…',
    '我是谁？我在哪？',
    '心服口服…',
    '这波操作太秀了',
    '青山不改，绿水长流！',
    '三十六计，走为上！',
    '今日之耻，来日必报！',
    '哎，技不如人，甘拜下风！',
    '罢了罢了…',
    '胜败乃兵家常事…',
    '饶命啊！我错了！',
    '呜呜呜……我输了……',
    '我，我这就走……',
    '别打啦！救命鸭！',
    '呜呜…饶了我吧…',
    '哼！下次一定赢你！',
    '溜了溜了…',
    '呜呜呜~我不行了…',
    '算…算你狠！',
  ]
  return randomPick(list)
}

// Boss 底部按钮文案
export function getBossButtonTitle(): string {
  // 随机文案
  const list = [
    '👍 我超棒的！',
    '😎 不愧是我！',
    '🚀 我做到了！',
    '💪 我可太棒了！',
    '🤩 我成功了！',
    '😎 So easy！',
    '💪 轻松拿捏！',
    '✌️ 小菜一碟！',
    '🔥 就是这么强！',
    '😎 学霸就是我！',
    '🌟 干得漂亮！',
    '🚀 必胜！',
    '👍 给自己点个赞！',
    '👑 知识就是力量！',
    '👍 优秀如我！',
    '🎉 Nice！赢啦！',
    '🏆 我能打十个！',
    '💯 这就是实力！',
    '✌️ 胜利！耶！',
  ]
  return randomPick(list)
}

export function withTimeout(
  promise: Promise<any>,
  timeout: number
): Promise<any> {
  return Promise.race([promise, wait(timeout)]) as any
}

const LOTTIES = import.meta.glob('../assets/lottie/*', {
  eager: true,
  query: '?url',
})
// 传入 lottie 的 name ，返回图片的完整路径
export function resolveLottiePath(name: string): string | undefined {
  for (const key in LOTTIES) {
    if (key.startsWith(`../assets/lottie/${name}.`)) {
      return (LOTTIES[key] as any).default
    }
  }

  return undefined
}

const AUDIOS = import.meta.glob('../assets/audio/*', { eager: true })
// 传入 audio 的 name ，返回图片的完整路径
export function resolveAudioPath(name: string): string | undefined {
  for (const key in AUDIOS) {
    if (key.startsWith(`../assets/audio/${name}.`)) {
      return (AUDIOS[key] as any).default
    }
  }

  return undefined
}

export function formatDeadline(deadline: string) {
  const now = _global.now()
  const ms = dayjs(deadline).diff(now, 'second')

  const days = Math.ceil(ms / (3600 * 24))
  const hours = Math.ceil(ms / 3600)
  const minutes = Math.ceil(ms / 60)

  if (hours >= 24) {
    return `${days} 天`
  } else if (minutes >= 60) {
    return `${hours} 小时`
  } else if (minutes) {
    return `${minutes} 分钟`
  } else {
    return ''
  }
}
export function vibrate(type: VibrateType = VibrateType.Light) {
  if (_global.isInsideApp && _store.vibrateFeedbackOn) {
    _bridge.vibrate(type)
  }
}

// url: object url
// maxLength: 需要截取的大小，单位为 byte
export async function readBlobUrlAsArrayBuffer(
  url: string,
  maxLength?: number
): Promise<Uint8Array> {
  const blob = await fetch(url).then(r => r.blob())
  const arrayBuffer = await blob.arrayBuffer()
  const bytes = new Uint8Array(arrayBuffer)
  return maxLength == null ? bytes : bytes.slice(0, maxLength)
}
