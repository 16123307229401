<template>
  <div ref="boxRef">
    <ColumnLayout
      :data="props.packages"
      :columns="columns"
    >
      <template #default="e">
        <PkgCard
          :pkg="e.item"
          :highlight="props.highlight"
          class="bg-white cursor-pointer pkg-card"
          :icon-font-size="iconFontSize"
          @click="emit('pkgClick', e.item)"
        />
      </template>
    </ColumnLayout>
  </div>
</template>

<script setup lang="ts">
import type { SearchedPackage } from '@/api/package-source'
import ColumnLayout from '../ColumnLayout.vue'
import PkgCard from './PkgCard.vue'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps<{
  packages: SearchedPackage[]
  highlight?: boolean
}>()
const emit = defineEmits<{
  pkgClick: [SearchedPackage]
}>()

const boxRef = ref<HTMLElement | null>(null)

onMounted(() => {
  autoLayout()
  // 监听窗口大小变化
  window.addEventListener('resize', autoLayout)
})
onUnmounted(() => {
  window.removeEventListener('resize', autoLayout)
})

function autoLayout() {
  if (boxRef.value) {
    const boxWidth = boxRef.value.clientWidth
    // 再根据列表区域的宽度，决定有几列
    // (0, 500] : 2 列
    // (500,680] :  3 列
    // (680, 860] :  4 列
    // (860 , 1024] : 5 列
    // 1024 +: 6 列
    if (boxWidth <= 500) {
      columns.value = 2
    } else if (boxWidth <= 680) {
      columns.value = 3
    } else if (boxWidth <= 860) {
      columns.value = 4
    } else if (boxWidth <= 1024) {
      columns.value = 5
    } else {
      columns.value = 6
    }

    // emoji 尺寸 = 图片宽度 * 0.5
    const itemWidth = (boxWidth - 12 * (columns.value - 1)) / columns.value
    const imageWidth = itemWidth - 24
    iconFontSize.value = `${imageWidth * 0.5}px`
  }
}
const columns = ref(2)
const iconFontSize = ref('54px')
</script>

<style scoped></style>
