import type { ContentTag, IdentityTag } from '@/types/core'

type Tag = ContentTag | IdentityTag

export function tree2Map<T extends Tag>(root: T | T[]): Map<string, T> {
  const map = new Map<string, T>()

  const stack = Array.isArray(root) ? [...root] : [root]

  while (stack.length > 0) {
    const current = stack.pop()!

    map.set(current.key, current)

    if (current.children != null) {
      stack.push(...(current.children as T[]))
    }
  }

  return map
}
