<template>
  <div class="hstack items-stretch card-edit h-full w-full">
    <div class="flex flex-col items-center overflow-hidden w-385px">
      <div class="phone-container border-box h-full">
        <CardPreview
          v-if="data.cardFace === PREVIEW_CARD"
          :key="cardJson"
          :card="data.card"
        ></CardPreview>

        <ConcreteCard
          v-else-if="face"
          :key="cardJson + data.cardFace"
          class="flex-1"
          :face="face"
        ></ConcreteCard>
      </div>

      <SelectButton
        :model-value="data.cardFace"
        :options="cardFaceOptions"
        optionLabel="label"
        optionValue="type"
        :allowEmpty="false"
        class="mt-2"
        @update:model-value="onCardFaceChange"
      ></SelectButton>
    </div>

    <div class="flex-1 flex flex-col overflow-scroll bg-ld-background">
      <TabView
        class="h-full"
        :activeIndex="store.cardEditTab"
        @update:active-index="store.setCardEditTab($event)"
      >
        <TabPanel :header="_t('修改内容')">
          <ClozeCardForm
            v-if="data.card.type === CardType.CLOZE"
            min-cols="30"
            :card="data.card"
            @change="onCardChange"
          ></ClozeCardForm>

          <WordCardForm
            v-if="data.card.type === CardType.EN_WORD"
            min-cols="30"
            :card="data.card"
            @change="onCardChange"
          ></WordCardForm>

          <MCQCardForm
            v-if="data.card.type === CardType.MCQ"
            :card="data.card"
            @change="onCardChange"
          ></MCQCardForm>

          <div
            v-if="cardInvalidMsg"
            class="text-red mt-4"
          >
            {{ cardInvalidMsg }}
          </div>
        </TabPanel>

        <TabPanel :header="_t('主题样式')">
          {{ _t('主题样式') }}
        </TabPanel>
        <TabPanel :header="_t('卡片信息')">
          <div class="vstack items-start h-full overflow-hidden">
            <div class="overflow-y-scroll flex-1">
              <CardSource
                :key="data.cardInfo.content"
                :card="data.cardInfo"
              />
            </div>

            <Button
              label="删除卡片"
              class="self-center mt-auto"
              scene="danger"
              @click="emit('delete')"
            ></Button>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
<script setup lang="ts">
import ConcreteCard from '@/components/ConcreteCard/ConcreteCard.vue'
import CardPreview from '@/components/CardPreview/CardPreview.vue'
import ClozeCardForm from './ClozeCardForm.vue'
import WordCardForm from './WordCardForm.vue'
import MCQCardForm from './MCQCardForm.vue'
import SelectButton from 'primevue/selectbutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import {
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
  MCQCardFaceType,
} from '@/types/core'
import { computed, reactive } from 'vue'
import { tryJSONParse } from '@/utils'
import { cloneDeep } from 'lodash-es'
import { useCommonStore } from '@/stores'
import CardSource from './CardSource.vue'

import type { CardResponse as CardResponse } from '@/api/package-source'
import type { CardFace, Card } from '@/types/core'
import { parseCard, removeCardEmptyFields, validateCard } from '@/utils/card'

const PREVIEW_CARD = 'PREVIEW'

const store = useCommonStore()

const props = defineProps<{
  card: CardResponse
  cards: CardResponse[]
}>()

const emit = defineEmits<{
  update: [CardResponse]
  delete: []
}>()

const data = reactive({
  cardFace: PREVIEW_CARD as CardFace['type'] | typeof PREVIEW_CARD,
  cardInfo: props.card,
  card: tryJSONParse(props.card.content, {
    type: CardType.CLOZE,
    content: '解析失败',
  }) as Card,
})

const cardJson = computed(() => JSON.stringify(filteredCard.value))

const filteredCard = computed(() => removeCardEmptyFields(data.card))

const cardInvalidMsg = computed(() => {
  if (data.card == null) return ''

  return validateCard(filteredCard.value)
})

const cardFaceOptions = computed(() => {
  const preivewOption = {
    label: '预览',
    type: PREVIEW_CARD,
  }

  switch (data.card.type) {
    case CardType.CLOZE:
      return [
        preivewOption,
        {
          label: '选择题',
          type: ClozeCardFaceType.Choice,
        },
        {
          label: '极简选择题',
          type: ClozeCardFaceType.MinimalChoice,
        },
        {
          label: '送分选择题',
          type: ClozeCardFaceType.GiveAwayChoice,
        },
        {
          label: '判断题',
          type: ClozeCardFaceType.Judgement,
        },
        {
          label: '送分判断题',
          type: ClozeCardFaceType.GiveAwayJudgement,
        },
      ]
    case CardType.EN_WORD:
      const faces = [
        preivewOption,
        {
          label: '单词选释义',
          type: EnWordCardFaceType.WordChoice,
        },
        {
          label: '例句选释义',
          type: EnWordCardFaceType.ExampleChoice,
        },
        {
          label: '释义选单词',
          type: EnWordCardFaceType.ExplainChoice,
        },
        {
          label: '选图',
          type: EnWordCardFaceType.PickImage,
        },
        {
          label: '听音选词',
          type: EnWordCardFaceType.VoiceChoice,
        },
      ]

      // TMP: prod 环境下暂时隐藏单词拼写卡面
      if (!_global.isProd) {
        faces.push({
          label: '拼写',
          type: EnWordCardFaceType.Spell,
        })
      }
      return faces
    case CardType.MCQ:
      return [
        preivewOption,
        {
          label: '选择题',
          type: MCQCardFaceType.Choice,
        },
        {
          label: '极简选择题',
          type: MCQCardFaceType.MinimalChoice,
        },
      ]

    default:
      return []
  }
})

const face = computed<CardFace | null>(() => {
  const altCards = props.cards
    .filter(item => item.id !== data.cardInfo.id)
    .map(item => JSON.parse(item.content) as Card)
    .filter(item => item.type === data.card.type)
    .map(card => parseCard(card))
    .filter(result => result.error == null)
    .map(result => result.card)

  const parsedResult = parseCard(data.card)

  if (parsedResult.error != null) return null

  return {
    type: data.cardFace,
    cardId: data.cardInfo.id,
    card: parsedResult.card,
    altCards,
    isFirstLearn: true,
    style: {
      showCardDetail: false,
      operationLayout: 'horizontal',
      hideRoleImage: false,
    },
  } as CardFace
})

function onCardFaceChange(cardFace: any) {
  data.cardFace = cardFace
}

function onCardChange(card: Card) {
  data.card = card

  const res = cloneDeep(props.card)
  res.content = JSON.stringify(filteredCard.value)
  emit('update', res)
}
</script>
<style scoped>
:deep(.phone-container) {
  min-height: 600px;
  height: 100%;
}

:deep(.p-tabview) {
  display: flex;
  flex-direction: column;
}

:deep(.p-tabview-panels) {
  flex: 1;
  overflow-y: hidden;
}

:deep(.p-tabview-panel) {
  height: 100%;
  overflow-y: scroll;
}
</style>
