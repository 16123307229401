import type { CardSchedule } from '@/api/learn'
import { merge, cloneDeep, sortBy } from 'lodash-es'
import {
  type CardFace,
  LearnStatus,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
  type VirtualCardBoss,
  VirtualCardType,
  MCQCardFaceType,
  type Card,
  type VirtualCardMatch,
  type EnWordCard,
  UnitEventType,
} from '@/types/core'
import { getContentClozes } from '@/utils/card'
import { randomPick, randomPickBoss } from '@/utils'
import { shuffle } from 'lodash-es'
import {
  parseCardSchedule,
  getAltCards,
  type QueueCard,
  orderCards,
} from '@/shared/queue'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'

// 生成配对卡需要的卡片数
const MATCH_CARD_COUNT = 5

// 队列学习阶段
export enum QueueStage {
  // 练习
  Practice = 'Practice',

  // boss
  Boss = 'Boss',

  // 完成
  Done = 'Done',
}

export type Frame = PracticeFrame | BossStartFrame | BossFrame | DoneFrame

export enum FrameType {
  Practice = 'practice',
  Done = 'done',
  Boss = 'boss',
  BossStart = 'bossStart',
}

type PracticeFrame = {
  type: FrameType.Practice
  face: CardFace
  cardId: number
}

type BossStartFrame = {
  type: FrameType.BossStart
  name: string
}

type BossFrame = {
  type: FrameType.Boss
  boss: VirtualCardBoss
}

type DoneFrame = {
  type: FrameType.Done
}

// 卡面难度配置参考：https://qianmojiaoyu.feishu.cn/wiki/YuJ7wp6ZSiMvVok24CUcGfhYngh
export const SingleClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayChoice, ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.MinimalChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const MultiClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.GiveAwayChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const EnWordCardDifficulty = {
  1: [EnWordCardFaceType.ExampleChoice],
  2: [EnWordCardFaceType.PickImage, EnWordCardFaceType.VoiceChoice],
  3: [EnWordCardFaceType.ExplainChoice, EnWordCardFaceType.WordChoice],
}

// TMP: prod 环境下暂时隐藏单词拼写卡面
if (!_global.isProd) {
  EnWordCardDifficulty[3].push(EnWordCardFaceType.Spell)
}

export const MCQCardDifficulty = {
  1: [MCQCardFaceType.MinimalChoice],
  2: [MCQCardFaceType.Choice],
  3: [MCQCardFaceType.Choice],
}

export interface InsertQueueState {
  frames: Frame[]
  cardDifficulty: Record<number, number>
  cardPracticed: Record<number, number>
  lastCard: Card | null
}

export type InsertQueueConfig = {
  // 练习阶段每张卡片的学习次数
  practiceCardTimes: number

  // 知识点卡片单个挖空的卡面难度配置
  singleClozeCardDifficulty: typeof SingleClozeCardDifficulty

  // 知识点卡片多个挖空的卡面难度配置
  multiClozeCardDifficulty: typeof MultiClozeCardDifficulty

  // 单词卡的卡面难度配置
  enWordCardDifficulty: typeof EnWordCardDifficulty

  // MCQ 卡的卡面难度配置
  mcqCardDifficulty: typeof MCQCardDifficulty
}

function getDifficultyByR(r: number) {
  if (r >= 95) {
    return 3
  } else if (r >= 90) {
    return 2
  } else {
    return 1
  }
}

// 不需要继承，这里就是实现一个 queue 给 InsertQueue.vue 使用
// 队列详细文档：https://qianmojiaoyu.feishu.cn/wiki/YuJ7wp6ZSiMvVok24CUcGfhYngh
export class InsertQueue {
  constructor(cards: CardSchedule[], config?: Partial<InsertQueueConfig>) {
    this.config = merge({}, defaultConfig, config ?? {})
    this.cards = cards.map(parseCardSchedule)
    this.debugInfo = {
      originConfig: config,
      config: this.config,
      cards: [...cards],
      logs: [],
    }
    this.debugLog({ msg: 'inited' })
    this.frames = this.genPracticeFrames()
    this.debugLog({
      msg: 'practice frames generated',
      frames: this.frames,
    })
  }

  setState(state: InsertQueueState) {
    this.debugLog({ msg: 'setState', state })

    this.frames = state.frames
    this.cardDifficulty = state.cardDifficulty
    this.cardPracticed = state.cardPracticed
    this.lastCard = state.lastCard
  }

  // 始终会保证有值
  currentFrame(): Frame {
    const frame = this.frames[0]!
    this.debugLog({ msg: 'get current frame', frame })
    return frame
  }

  getLastCard(): Card | null {
    return this.lastCard
  }

  tick(star?: FeedbackStar) {
    this.tickCount++
    const idx = this.tickCount

    this.debugLog({
      msg: `tick[${idx}]: start to tick`,
      star,
      frames: this.frames,
    })

    const frame = this.currentFrame()

    _global.assert(frame != null, 'frame should never be null', this.debugInfo)

    if (frame.type === FrameType.Done) {
      this.debugLog({ msg: `tick[${idx}]: done frame` })
      return
    }

    // 当前还处于练习阶段
    if (frame.type === FrameType.Practice) {
      this.debugLog({ msg: `tick[${idx}]: practice stage` })
      this.nextFrame()
      this.practiceCard(frame.cardId)

      // 如果学习次数超过设定次数，弃牌
      if (this.cardPracticed[frame.cardId] >= this.config.practiceCardTimes) {
        this.debugLog({ msg: `tick[${idx}]: exceeded practice times` })
        // 如果练习全部学完，则生成 boss frames
        if (this.frames.length === 0) {
          this.frames = this.genBossFrames()
          this.debugLog({
            msg: `tick[${idx}]: boss frames generated`,
            frames: this.frames,
          })
        }

        return
      }

      // 答对难度 + 1，答错难度 - 1
      const difficulity =
        star === FeedbackStar.Three
          ? this.increaseCardDifficulty(frame.cardId)
          : this.decreaseCardDifficulty(frame.cardId)

      // 新的插入规则： 无论对错，第一张卡片插到第二张卡片后面，之后所有的卡片，都插入到两张卡片之后
      // insert after this index
      let index: number

      if (this.practiceCount <= 1) {
        index = this.frames.length > 1 ? 1 : 0
      } else {
        index = this.frames.length > 2 ? 2 : this.frames.length
      }

      this.frames.splice(index, 0, {
        type: FrameType.Practice,
        cardId: frame.cardId,
        face: this.genCardFace(frame.cardId, difficulity, {
          hideRoleImage: false,
          isFirstLearn: false,
          showCardDetail: true,
        }),
      })
      this.debugLog({
        msg: `tick[${idx}]: rearrange frames`,
        index,
        frames: this.frames,
      })
    }

    if (frame.type === FrameType.BossStart || frame.type === FrameType.Boss) {
      this.debugLog({ msg: `tick[${idx}]: bossStart of boss frame` })
      this.nextFrame()
      return
    }
  }

  getState(): InsertQueueState {
    return {
      frames: this.frames,
      cardDifficulty: this.cardDifficulty,
      cardPracticed: this.cardPracticed,
      lastCard: this.lastCard,
    }
  }

  // 练习阶段的进度
  get practiceProgress() {
    return (
      (this.practiceCount /
        (this.cards.length * this.config.practiceCardTimes)) *
      100
    )
  }

  get isDone() {
    const frame = this.currentFrame()
    return frame == null || frame.type === FrameType.Done
  }

  // 练习阶段的学习次数
  private get practiceCount() {
    return Object.values(this.cardPracticed).reduce((acc, cur) => acc + cur, 0)
  }

  private cards: QueueCard[] = []
  config: InsertQueueConfig
  debugInfo: {
    originConfig: Partial<InsertQueueConfig> | undefined
    config: InsertQueueConfig
    cards: CardSchedule[]
    logs: any[]
  }
  private tickCount = 0
  private debugLog(input: object) {
    const payload = cloneDeep(input) as any
    payload.ts = Date.now()
    this.debugInfo.logs.push(payload)
  }

  private frames: Frame[] = []

  private lastCard: Card | null = null

  // 每个卡片当前的难度
  private cardDifficulty: Record<number, number> = {}

  // 每个卡片练习阶段已经学了多少次
  cardPracticed: Record<number, number> = {}

  // 根据卡片+难度生成需要渲染的卡片
  private genCardFace(
    cardId: number,
    difficulity: 1 | 2 | 3,
    {
      hideRoleImage,
      isFirstLearn,
      showCardDetail,
    }: {
      hideRoleImage: boolean
      isFirstLearn: boolean
      showCardDetail: boolean
    }
  ): CardFace {
    const item = this.cards.find(item => item.cardId === cardId)
    if (item == null) {
      throw '[insertQueue] unkown card id when generate card face'
    }
    const altCards = getAltCards(this.cards, item.cardId, item.card)

    let faceType: ClozeCardFaceType | EnWordCardFaceType | MCQCardFaceType

    switch (item.card.type) {
      case CardType.CLOZE: {
        const isSingleCloze = getContentClozes(item.card.content).length === 1
        const config = isSingleCloze
          ? this.config.singleClozeCardDifficulty
          : this.config.multiClozeCardDifficulty
        faceType = randomPick(config[difficulity])
        break
      }
      case CardType.EN_WORD:
        faceType = randomPick(this.config.enWordCardDifficulty[difficulity])
        break
      case CardType.MCQ:
        faceType = randomPick(this.config.mcqCardDifficulty[difficulity])
        break
      default:
        _global.unreachable(item.card)
    }

    return {
      type: faceType!,
      cardId: item.cardId,
      card: item.card,
      altCards,
      isFirstLearn,
      style: {
        showCardDetail,
        hideTip: false,
        hideRoleImage,
        operationLayout: 'horizontal',
      },
    } as CardFace
  }

  private genPracticeFrames(): PracticeFrame[] {
    const frames: PracticeFrame[] = []
    const orderedCards = orderCards(this.cards)

    for (const item of orderedCards) {
      const difficulity =
        item.cardStatus === LearnStatus.DEBUT
          ? 1
          : getDifficultyByR(item.r * 100)

      this.cardDifficulty[item.cardId] = difficulity

      frames.push({
        type: FrameType.Practice,
        face: this.genCardFace(item.cardId, difficulity, {
          hideRoleImage: false,
          isFirstLearn: item.cardStatus === LearnStatus.DEBUT,
          showCardDetail: true,
        }),
        cardId: item.cardId,
      })
    }

    return frames
  }

  private tryGenerateMatchCard(): VirtualCardMatch | undefined {
    const enCards: EnWordCard[] = []

    // 优先找做错过的
    const sortedCards = sortBy(this.cards, item => {
      const events = _store.stageUnit!.events[item.cardId] ?? []

      if (events.some(evt => evt.event === UnitEventType.WRONG)) {
        return -1
      }

      return 0
    })

    for (const card of sortedCards) {
      if (card.card.type !== CardType.EN_WORD) return

      if (enCards.length >= MATCH_CARD_COUNT) {
        break
      }

      enCards.push(card.card)
    }

    if (enCards.length >= MATCH_CARD_COUNT) {
      return {
        type: VirtualCardType.Match,
        cards: shuffle(enCards),
      }
    }
  }

  private genBossFrames(): (BossStartFrame | BossFrame | DoneFrame)[] {
    const faces: CardFace[] = []

    for (const item of shuffle(this.cards)) {
      const difficulity = this.increaseCardDifficulty(item.cardId)

      faces.push(
        this.genCardFace(item.cardId, difficulity, {
          hideRoleImage: true,
          isFirstLearn: false,
          showCardDetail: false,
        })
      )
    }

    const bossName = randomPickBoss()
    const matchCard = this.tryGenerateMatchCard()
    const totalHpLengths =
      faces.length + (matchCard != null ? MATCH_CARD_COUNT : 0)
    const tickHp = Math.ceil((1 / totalHpLengths) * 100)

    return [
      {
        type: FrameType.BossStart,
        name: bossName,
      },
      ...faces.map((face, i) => {
        return {
          type: FrameType.Boss,
          boss: {
            type: VirtualCardType.Boss,
            cardFace: face,
            name: bossName,
            tickHp,
            hp: (totalHpLengths - i) * tickHp,
          },
        } as BossFrame
      }),
      ...(matchCard == null
        ? [
            {
              type: FrameType.Boss,
              boss: {
                type: VirtualCardType.Boss,
                name: bossName,
                hp: 0,
                tickHp,
              },
            } as BossFrame,
          ]
        : [
            {
              type: FrameType.Boss,
              boss: {
                type: VirtualCardType.Boss,
                name: bossName,
                hp: (MATCH_CARD_COUNT / totalHpLengths) * 100,
                tickHp,
                cardFace: matchCard,
              },
            } as BossFrame,
          ]),

      {
        type: FrameType.Done,
      },
    ]
  }

  private practiceCard(cardId: number) {
    const practicedCount = this.cardPracticed[cardId] ?? 0
    this.cardPracticed[cardId] = practicedCount + 1
  }

  private increaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity >= 3) {
      this.cardDifficulty[cardId] = 3
    } else {
      this.cardDifficulty[cardId] = difficulity + 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  private decreaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity <= 1) {
      this.cardDifficulty[cardId] = 1
    } else {
      this.cardDifficulty[cardId] = difficulity - 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  private nextFrame() {
    const frame = this.currentFrame()

    if (frame.type === FrameType.Practice) {
      this.lastCard = frame.face.card
    } else if (
      frame.type === FrameType.Boss &&
      frame.boss.cardFace?.type !== VirtualCardType.Match
    ) {
      this.lastCard = frame.boss.cardFace?.card ?? null
    }
    this.frames.shift()
  }
}

export const defaultConfig: InsertQueueConfig = {
  practiceCardTimes: 2,
  singleClozeCardDifficulty: SingleClozeCardDifficulty,
  multiClozeCardDifficulty: MultiClozeCardDifficulty,
  enWordCardDifficulty: EnWordCardDifficulty,
  mcqCardDifficulty: MCQCardDifficulty,
}
