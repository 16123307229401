<template>
  <div class="w-full p-4 flex flex-col items-center">
    <div class="w-full flex items-enter h-24px">
      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>

    <Img
      name="ld-greet"
      class="w-85px"
    />

    <div class="text-19px font-semibold mt-3 mb-2">{{ _t('音量有点低') }}</div>
    <div class="text-17px font-semibold text-ld-label-secondary">
      {{ _t('声音调大一点，才能听得清哦') }}
    </div>

    <Button
      class="w-full mt-4"
      :label="_t('我知道了')"
      @click="emit('done')"
    />
  </div>
</template>
<script lang="ts" setup>
const emit = defineEmits<{
  done: []
}>()
</script>
<style scoped></style>
