<template>
  <div class="flex-1 overflow-y-auto">
    <div class="text-20px">{{ props.title }}</div>
    <div class="break-all">{{ props.extra }}</div>
  </div>

  <Button
    class="w-fit ml-auto"
    @click="onErrorCopied"
  >
    {{ _t('复制错误内容') }}
  </Button>
</template>
<script lang="ts" setup>
import { useCopy } from '@/hooks'

const props = defineProps<{
  title: string
  extra?: any
}>()
const copy = useCopy()

function onErrorCopied() {
  copy(
    JSON.stringify({
      title: props.title,
      extra: props.extra,
    })
  ).then(() => {
    _message.success(_t('已复制'))
  })
}
</script>
<style scoped></style>
