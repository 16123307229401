<template>
  <div class="flex flex-col">
    <BossBattle
      :face="current"
      :npc="{ hp: face.hp, avatar: npcAvatar }"
      :defeated="defeated"
      :combo="combo"
      @event="onEvent"
      @next="onNext"
      @star="onStar"
      @done="onDone"
      @cardEnter="emit('cardEnter')"
      @close="emit('close')"
      @matchCorrect="emit('matchCorrect')"
    >
      <template #actions>
        <slot name="actions"></slot>
      </template>
    </BossBattle>
  </div>
</template>

<script setup lang="ts">
import { UnitEventType, VirtualCardType } from '@/types/core'
import { computed, provide } from 'vue'
import { useHotKey } from '@/hooks'
import type { VirtualCardBoss } from '@/types/core'
import BossBattle from '../BossBattle.vue'
import type { FeedbackStar } from '../ConcreteCard/common/feedback'
import { getBossHalf } from '@/utils'

useHotKey('enter,space', () => {
  if (current.value == null) {
    onDone()
  }
})

const props = defineProps<{
  face: VirtualCardBoss
  combo: number
}>()

const emit = defineEmits<{
  event: [cardId: number, UnitEventType]
  done: []
  close: []
  star: [FeedbackStar]
  next: []
  cardEnter: []
  matchCorrect: []
}>()

const npcAvatar = computed(() => {
  return getBossHalf(props.face.name)
})

// 要显示的卡片
const current = computed<any>(() => {
  // boss 卡片 隐藏角色形象
  if (props.face.cardFace == null) return

  if (props.face.cardFace.type === VirtualCardType.Match) {
    return props.face.cardFace
  }

  return {
    ...props.face.cardFace,
    style: {
      ...props.face.cardFace?.style,
      hideRoleImage: true,
    },
  }
})

const defeated = computed(() => props.face.hp <= 0)

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距改为 8px
provide('bossName', props.face.name)

function onDone() {
  emit('done')
}
function onStar(star: FeedbackStar) {
  emit('star', star)
}

function onNext() {
  emit('next')
}

function onEvent(_: number | undefined, event: UnitEventType) {
  if (current.value != null && current.value.type !== VirtualCardType.Match) {
    emit('event', current.value.cardId, event)
  }
}
</script>

<style scoped></style>
