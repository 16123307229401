import { type CardSchedule } from '@/api/learn'
import { getAltCards, parseCardSchedule, type QueueCard } from '@/shared/queue'
import {
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
  UnitEventType,
  type CardFace,
  MCQCardFaceType,
} from '@/types/core'
import { shuffle } from 'lodash-es'

export type Frame = {
  face: CardFace
  cardId: number
}

export interface DuelQueueState {
  npcHp: number
  playerHp: number
  fullNpcHp: number
  fullPlayerHp: number
  frames: Frame[]
}

export class DuelQueue {
  constructor(cards: CardSchedule[]) {
    this.npcHp = cards.length
    this.fullNpcHp = cards.length
    this.playerHp = Math.floor(cards.length / 2)
    this.fullPlaylerHp = this.playerHp
    this.frames = this.genFrames(cards.map(parseCardSchedule))
  }

  getState(): DuelQueueState {
    return {
      npcHp: this.npcHp,
      playerHp: this.playerHp,
      fullPlayerHp: this.fullPlaylerHp,
      fullNpcHp: this.fullNpcHp,
      frames: this.frames,
    }
  }

  setState(state: DuelQueueState) {
    this.npcHp = state.npcHp
    this.playerHp = state.playerHp
    this.fullPlaylerHp = state.fullPlayerHp
    this.fullNpcHp = state.fullNpcHp
    this.frames = state.frames
  }

  get npcHpPercent() {
    return this.npcHp <= 0 ? 0 : (this.npcHp / this.fullNpcHp) * 90 + 10
  }

  get playerHpPercent() {
    return this.playerHp <= 0
      ? 0
      : (this.playerHp / this.fullPlaylerHp) * 90 + 10
  }

  get leftBlood() {
    return this.playerHp / this.fullPlaylerHp
  }

  get isVictory() {
    return this.npcHp <= 0
  }

  get isDefeated() {
    return this.playerHp <= 0
  }

  currentFrame() {
    return this.frames[0]
  }

  tick(event: UnitEventType) {
    const frame = this.currentFrame()

    if (frame == null) return

    // 每张卡片答错只扣一次血量
    if (event === UnitEventType.WRONG && !this.wrongCardIds.has(frame.cardId)) {
      this.playerHp--
      this.wrongCardIds.add(frame.cardId)
    } else if (event === UnitEventType.CORRECT) {
      this.npcHp--
      this.frames.shift()
    }
  }

  private npcHp: number
  private playerHp: number
  private fullNpcHp: number
  private fullPlaylerHp: number

  private frames: Frame[] = []

  private wrongCardIds: Set<number> = new Set()

  private genFrames(cards: QueueCard[]): Frame[] {
    const frames: Frame[] = []

    const cardFaceMap = {
      [CardType.CLOZE]: ClozeCardFaceType.Choice,
      [CardType.EN_WORD]: EnWordCardFaceType.WordChoice,
      [CardType.MCQ]: MCQCardFaceType.Choice,
    }

    for (const item of shuffle(cards)) {
      const altCards = getAltCards(cards, item.cardId, item.card)

      frames.push({
        cardId: item.cardId,
        face: {
          type: cardFaceMap[item.card.type],
          cardId: item.cardId,
          card: item.card,
          altCards,
          isFirstLearn: false,
          style: {
            hideRoleImage: true,
            showCardDetail: false,
          },
        },
      } as Frame)
    }

    return frames
  }
}
