<template>
  <div
    v-if="defeated"
    class="defeated-bg flex flex-col h-full g-safe-area"
  >
    <div class="flex-1"></div>
    <div class="m-4 flex items-center justify-center">
      <Button
        v-if="afterBossDefeated"
        class="w-full g-fade-in max-w-700px"
        :label="getBossButtonTitle()"
        @click="onDone"
      ></Button>
    </div>
  </div>
  <SafeAreaTopSpacer class="bg-ld-brand-100" />
  <div class="flex flex-col g-header-width relative">
    <Icon
      v-if="player"
      name="arch"
      preserveAspectRatio="none"
      class="absolute top-0 w-full h-80px text-ld-brand-100"
    ></Icon>

    <Icon
      v-else
      name="arc"
      preserveAspectRatio="none"
      class="absolute top-0 w-full h-107px text-ld-brand-100"
    ></Icon>

    <div class="h-44px flex px-4 z-1 items-center">
      <Icon
        name="close"
        class="cursor-pointer w-20px"
        @click="emit('close')"
      ></Icon>

      <div class="ml-auto">
        <slot name="actions"></slot>
      </div>
    </div>

    <div
      v-if="player"
      class="relative flex mx-16px h-44px"
    >
      <div class="flex-1 flex">
        <Avatar
          :avatar="player.avatar"
          class="user-avatar player-shake"
          imgStyle="s"
        />
        <div
          class="flex-1 flex flex-col items-start justify-between py-4px ml--4"
        >
          <ProgressBar
            :percent="player.hp"
            :color="playerProgressColor"
            align="left"
            :lottie="false"
            :highlight="false"
          />
          <div class="user-name ml-6 line-clamp-1">{{ player.name }}</div>
        </div>
      </div>

      <Icon
        name="sword"
        class="h-44px"
      />
      <div class="flex-1 flex">
        <div
          class="flex-1 flex flex-col items-end justify-between py-4px relative mr--4"
        >
          <ProgressBar
            v-if="npc"
            :percent="npc?.hp"
            :color="npcProgressColor"
            align="right"
            :highlight="false"
            :lottie="false"
          />
          <div
            v-if="npc?.name"
            class="npc-name mr-6 line-clamp-1"
          >
            {{ npc?.name }}
          </div>
          <transition
            name="monster-hint"
            mode="out-in"
          >
            <span
              v-if="monsterHint"
              class="monster-hint top--20px"
            >
              {{ monsterHint }}
            </span>
          </transition>
        </div>
        <Img
          v-if="npc?.avatar"
          class="npc-avatar npc-shake"
          :name="npc?.avatar"
        />
      </div>
      <div></div>
    </div>

    <div
      v-else-if="npc"
      class="flex flex-col items-center justify-center mt--22px relative mx-36px z-2"
    >
      <div
        v-if="npc?.avatar"
        class="overflow-hidden"
        :class="{ 'npc-defeated-gray': afterHpClear }"
      >
        <Img
          :name="npc?.avatar"
          class="w-144px npc-shake"
          :class="{ 'npc-defeated-rotate': afterHpClear }"
        />
      </div>

      <ProgressBar
        :percent="npc?.hp"
        :color="npcProgressColor"
        class="mt--16px"
        :highlight="false"
        :lottie="false"
      />
      <transition
        name="monster-hint"
        mode="out-in"
      >
        <span
          v-if="monsterHint"
          class="monster-hint bottom-20px"
        >
          {{ monsterHint }}
        </span>
      </transition>

      <div
        v-if="afterBossDefeated"
        class="bubble"
      >
        <span class="text-17px">{{ getBossBubbleText() }}</span>
      </div>
    </div>
  </div>

  <transition
    name="slide"
    mode="out-in"
    @after-enter="emit('cardEnter')"
  >
    <Match
      v-if="face?.type === VirtualCardType.Match"
      :match="face"
      @correct="emit('matchCorrect')"
    />

    <ConcreteCard
      v-else-if="face"
      :key="cardLearnRound"
      :face="face"
      class="flex-1"
      @event="onEvent"
      @star="onStar"
      @next="onNext"
      @close="emit('close')"
    ></ConcreteCard>

    <slot
      v-else
      name="end"
    ></slot>
  </transition>
</template>

<script setup lang="ts">
import {
  type CardFace,
  UnitEventType,
  type VirtualCardMatch,
  VirtualCardType,
} from '@/types/core'
import ConcreteCard from './ConcreteCard/ConcreteCard.vue'
import type { FeedbackStar } from './ConcreteCard/common/feedback'
import ProgressBar from './ProgressBar.vue'
import { ref, watch, nextTick } from 'vue'
import {
  getBossBubbleText,
  getBossButtonTitle,
  vibrate,
  VibrateType,
} from '@/utils'
import Match from './VirtualCard/Match.vue'

const props = defineProps<{
  combo: number
  face?: CardFace | VirtualCardMatch

  defeated?: boolean

  npc?: {
    hp: number
    avatar?: string
    name?: string
  }

  player?: {
    hp: number
    avatar?: string
    name?: string
  }
}>()

const emit = defineEmits<{
  close: []
  event: [cardId: number | undefined, UnitEventType]
  star: [star: FeedbackStar]
  next: []
  done: []
  matchCorrect: []
  cardEnter: []
}>()

const monsterHint = ref('')

// boss hp 为0 后 -> boss 头像变灰旋转
const afterHpClear = ref(false)

// boss 头像变灰旋转后 -> 显示气泡和底部 button
const afterBossDefeated = ref(false)

watch(
  () => props.npc?.hp ?? 0,
  (newHp, oldHp) => {
    if (newHp < oldHp) {
      _am.playAudio('public_hit')
      animateCSS('.npc-shake', 'shake', '')
      // boss 受击震动
      vibrate(VibrateType.Soft)
    }
  }
)

watch(
  () => props.player?.hp ?? 0,
  (newHp, oldHp) => {
    if (newHp < oldHp) {
      animateCSS('.player-shake', 'shake', '')
    }
  }
)

watch(
  () => props.defeated ?? false,
  defeated => {
    if (defeated) {
      // boss 击败震动
      vibrate(VibrateType.Error)
      // 扣血动画 400ms
      // boss 变灰旋转动画 300ms

      setTimeout(() => {
        afterHpClear.value = true
      }, 400)
      setTimeout(() => {
        afterBossDefeated.value = true
        _am.playAudio('compress-monster_cry')
      }, 700)
    }
  },
  {
    immediate: true,
  }
)

const cardLearnRound = ref(0)

// 每次当卡面变化时都需要更新一下渲染的 round
// 防止出现重复的卡片时没有更新渲染
watch(
  () => props.face,
  () => {
    cardLearnRound.value++
  }
)

function onEvent(cardId: number | undefined, event: UnitEventType) {
  emit('event', cardId, event)
}

function onStar(star: FeedbackStar) {
  emit('star', star)

  // 上层先处理 combo 的逻辑后再显示，所以这里需要用 nextTick
  nextTick(() => {
    showComboHint()
  })
}
function onNext() {
  emit('next')
}
function onDone() {
  emit('done')
}

function showComboHint() {
  if (props.combo > 1) {
    monsterHint.value = `Combo x ${props.combo}`
  } else {
    monsterHint.value = `Hit + 1`
  }
  setTimeout(() => {
    monsterHint.value = ''
  }, 500)
}

const animateCSS = (element: string, animation: string, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise(resolve => {
    const animationName = `${prefix}${animation}`
    const node = document.querySelector(element)

    node?.classList.add(`${prefix}animated`, animationName)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: any) {
      event.stopPropagation()
      node?.classList.remove(`${prefix}animated`, animationName)
      resolve('Animation ended')
    }

    node?.addEventListener('animationend', handleAnimationEnd, { once: true })
  })

const npcProgressColor =
  'linear-gradient(270deg, var(--red-500) 0.01%, var(--ld-progress-red-a50) 99.98%)'

const playerProgressColor =
  'linear-gradient(270deg, var(--ld-progress-green-a50) 0.01%, var(--green-500) 99.98%)'
</script>

<style scoped>
.npc-avatar,
.user-avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  flex-shrink: 0;
  z-index: 1;
}

.npc-avatar {
  border: 2px solid var(--red-500);
}

.user-avatar {
  border: 2px solid var(--green-500);
}

.npc-name,
.user-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}

.monster-hint {
  position: absolute;
  left: 0;
  color: var(--red-400);
  font-family: Comic Sans MS;
  font-size: 15px;
  font-weight: 400;
  transform: rotate(-5.89deg);
  z-index: 2;
  white-space: nowrap;
}

/* npc-half 被击败状态 */

.npc-defeated-gray {
  filter: grayscale(100%);
  transition: filter 0.3s;
}
.npc-defeated-rotate {
  transform: rotate(-8deg);
  transition: transform 0.3s;
}

.circle-bg {
  position: absolute;
  left: -50%;
  width: 200%;
  height: 100px;
  background-color: var(--ld-brand-100);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.2s ease-in-out;
}

.monster-hint-enter-active,
.monster-hint-leave-active {
  transition: opacity 500ms;
}

.monster-hint-enter {
  opacity: 1; /* 初始位置 */
}
.monster-hint-leave-to {
  opacity: 0; /* 最终位置 */
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 300ms; /* 过渡效果的时间 */
}
.slide-enter-from {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0%);
}
.slide-leave-from {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(-100%); /* 最终位置 */
}
.defeated-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bossDefeatedBg);
  z-index: 1;
}
/* 气泡 显示在进度条下面 20 px */
.bubble {
  background-color: white;
  border-radius: 2px;
  outline: 2px solid var(--ld-border);
  padding: 12px;
  position: absolute;
  top: calc(100% + 20px);
}
.bubble::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-color: transparent;
  border-bottom-color: var(--ld-border);
  border-width: 10px;
}
.bubble::after {
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-color: transparent;
  border-bottom-color: white;
  border-width: 8px;
}
</style>
