<template>
  <div class="bg-ld-background h-full flex flex-col">
    <RatioSpacedContainer class="flex-1 overflow-auto">
      <main class="flex flex-col items-center">
        <AuthHeader
          :title="_t('登录')"
          class="w-full"
        />

        <div class="auth-container bg-white p-10 min-w-360px">
          <AuthForm
            v-if="isEmailLogin"
            class="mx-auto my-4"
            @done="onAuthDone"
          />

          <WxLogin
            v-else
            @done="onAuthDone"
          />
        </div>

        <TextButton
          :label="isEmailLogin ? _t('切换至微信登录') : _t('切换至密码登录')"
          class="mt-4"
          @click="onLoginTypeSwitch"
        ></TextButton>
      </main>
    </RatioSpacedContainer>

    <CopyrightFooter class="mx-auto mb-8" />
  </div>
</template>

<script lang="ts" setup>
import AuthForm from '@/components/AuthForm/AuthForm.vue'
import WxLogin from '@/components/WxLogin.vue'
import AuthHeader from '@/components/AuthHeader.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import CopyrightFooter from '@/components/CopyrightFooter.vue'
import type { UserInfo } from '@/api/auth'
import { useCommonStore } from '@/stores'
import { afterLogin } from '@/shared/landing'

// query:
//  - redirect?: redirect url

const store = useCommonStore()
const route = useRoute()
const router = useRouter()
const isEmailLogin = ref(false)

function onLoginTypeSwitch() {
  isEmailLogin.value = !isEmailLogin.value
}

async function onAuthDone(token: string, user: UserInfo) {
  store.login(token, user)
  afterLogin(router, route)
}
</script>

<style scoped>
.auth-container {
  box-shadow: 0px 2px 4px 0px var(--ld-shadow);
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}
</style>
