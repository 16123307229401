<template>
  <div
    class="flex flex-col gap-2 pb-2 relative transition-left duration-300"
    :style="{
      left: props.show ? '0' : '100%',
    }"
  >
    <ContentTagList
      :selectedTagKey="data.selectedTagKey"
      :tags="data.contentTags"
      class="sticky top--0.5px z-1 bg-ld-background"
      @select="onTagSelect"
    />

    <PkgCardList
      :packages="data.recommendPackages"
      @pkgClick="pkg => emit('pkgClick', pkg)"
    />

    <Loading
      v-if="data.loading"
      class="h-full"
    />

    <div
      v-if="data.error"
      class="flex gap-2 items-center justify-center text-ld-label-secondary text-19px py-4"
    >
      <div>{{ _t('加载失败') }}</div>
      <div
        class="cursor-pointer flex text-ld-brand-500"
        @click="fetchPackages()"
      >
        <Icon
          name="refresh"
          class="w-20px"
          @click="fetchPackages"
        />
        <div>{{ _t('立即刷新') }}</div>
      </div>
    </div>
    <div
      v-if="data.noMoreData"
      class="flex flex-col text-19px text-center text-ld-label-secondary py-4"
    >
      <div>{{ _t('心仪卡包找不到？不如自己来创造') }}</div>
      <div>
        <span>
          {{ _t('去试试') }}
        </span>
        <TextButton
          :label="_t('创建自己的卡包')"
          class="create-pkg-btn mx-2px"
          @click="onPackageCreate"
        ></TextButton>
        <span>{{ _t('吧') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  fetchRecommendPackages,
  fetchRecommendTags,
  type ContentTagWithParent,
  type PackageBasic,
  type SearchedPackage,
} from '@/api/package-source'

import { nextTick, reactive, toRefs } from 'vue'

import { useInfiniteScroll } from '@vueuse/core'
import PkgCardList from './pkg-search/PkgCardList.vue'
import PkgForm from '@/components/PkgList/PkgForm.vue'
import { useRouter } from 'vue-router'
import ContentTagList from './ContentTagList.vue'

const router = useRouter()

const props = withDefaults(
  defineProps<{
    show?: boolean
    scrollContainer?: HTMLDivElement
  }>(),
  {
    show: true,
    scrollContainer: undefined,
  }
)

const emit = defineEmits<{
  scrollToTop: []
  pkgClick: [SearchedPackage]
}>()

const { scrollContainer } = toRefs(props)

useInfiniteScroll(scrollContainer, () => fetchPackages(), {
  distance: 20,
  interval: 300,
  canLoadMore: () => !data.noMoreData && !data.error,
})

const data = reactive({
  selectedTagKey: undefined as string | undefined,
  contentTags: [] as ContentTagWithParent[],
  searchLogs: [] as string[],
  recommendPackages: [] as SearchedPackage[],
  recommendOffset: 0,
  // 加载中
  loading: false,
  // 加载失败
  error: false,
  // 无更多数据
  noMoreData: false,
})

async function fetchPackages(reset?: boolean) {
  if (data.loading) return

  data.loading = true
  data.error = false

  try {
    const res = await fetchRecommendPackages({
      offset: data.recommendOffset,
      contentTagKey: data.selectedTagKey,
      limit: 50,
    })

    if (reset) {
      data.recommendPackages = res.packages
    } else {
      data.recommendPackages.push(...res.packages)
    }

    if (data.recommendPackages.length >= res.total) {
      data.noMoreData = true
      data.loading = false
      return
    }
    data.recommendOffset = res.offset + res.limit
  } catch {
    data.error = true
  }

  data.loading = false
}

function onTagSelect(tagKey?: string) {
  data.noMoreData = false
  data.recommendOffset = 0
  data.selectedTagKey = tagKey

  // 获取到卡包列表之后，需要重新定位到第一个卡包的位置
  fetchPackages(true).then(() => {
    nextTick(() => {
      emit('scrollToTop')
    })
  })
}

function fetchTags() {
  fetchRecommendTags().then(res => {
    data.contentTags = res.contentTags
  })
}

onInit(() => {
  fetchTags()
  fetchPackages()
})

function onPackageCreate() {
  _openDialog(PkgForm, {
    title: '新建卡包',
    props: {
      onCreate(pkg: PackageBasic) {
        router.push({ name: 'package', params: { id: pkg.id } })
      },
    },
    rootClass: 'p-0',
    dialog: {
      showHeader: false,
      dismissableMask: true,
    },
  })
}
</script>

<style scoped>
.create-pkg-btn :deep(button) {
  font-size: 19px;
}
</style>
