<template>
  <div class="flex items-center px-4 my-2">
    <i
      :class="['pi pi-chevron-left cursor-pointer mr-4']"
      @click="onBack"
    ></i>

    <div class="flex flex-1 search-bar items-center">
      <Icon
        name="search"
        class="w-20px text-gray-500"
      />
      <InputText
        ref="searchInput"
        v-model="data.keyword"
        class="flex-1 min-w-10"
        placeholder="输入关键词按回车进行搜索"
        enterkeyhint="search"
        @keydown.enter="onSearchByEnter"
      ></InputText>

      <Icon
        v-if="data.keyword.trim().length > 0"
        name="close-circle"
        class="w-20px mr-1 cursor-pointer"
        @click="onKeywordClear"
      />
    </div>

    <TextButton
      v-if="props.navigator === 'bottomSheet'"
      :label="_t('完成')"
      class="ml-4"
      @click="onBack"
    />

    <i
      v-else-if="props.navigator === 'modal'"
      :class="['pi pi-times cursor-pointer ml-4']"
      @click="onBack"
    ></i>
  </div>

  <div
    v-if="data.searchedPackages == null"
    ref="recommendContainer"
    class="flex flex-col gap-3 flex-1 overflow-y-auto px-4"
  >
    <div
      v-if="data.searchLogs.length > 0"
      class="flex flex-col gap-2"
    >
      <div>
        {{ _t('最近搜索') }}
      </div>

      <div class="flex items-center">
        <div class="flex gap-2 flex-1 flex-wrap min-w-0">
          <div
            v-for="keyword of data.searchLogs"
            :key="keyword"
            class="keyword-log shrink-0"
            @click="onKeywordLogSearch(keyword)"
          >
            {{ keyword }}
          </div>
        </div>

        <i
          class="pi pi-trash cursor-pointer p-2"
          @click="onSearchLogsClear"
        ></i>
      </div>
    </div>

    <div class="flex flex-col gap-2 flex-1">
      <div v-if="!store.isAndroidReviewing">
        {{ _t('为你推荐') }}
      </div>

      <RecommendPkgList
        :scroll-container="recommendContainer"
        class="gap-2 flex-1 recommend-list"
        @pkgClick="pkg => emit('pkgClick', pkg)"
        @scrollToTop="onScrollToTop"
      />
    </div>
  </div>

  <RatioSpacedContainer
    v-else-if="data.searchedPackages.length === 0"
    class="h-full px-4"
  >
    <div class="flex flex-col items-center justify-center">
      <Icon name="no-search-result" />
      <div class="text-21px">{{ _t('找不到相关卡包') }}</div>
      <div class="text-17px text-ld-label-secondary">
        {{ _t('请试试调整关键词、重新搜索') }}
      </div>
      <div>
        <span>
          {{ _t('或') }}
        </span>
        <TextButton
          :label="_t('自己创建卡包')"
          class="create-pkg-btn mx-2px"
          @click="onPackageGet"
        ></TextButton>
      </div>
    </div>
  </RatioSpacedContainer>

  <div
    v-else
    ref="searchContainer"
    class="overflow-y-auto"
  >
    <ContentTagList
      v-if="data.contentTags.length > 1"
      :selectedTagKey="data.selectedTagKey"
      :tags="data.contentTags"
      class="sticky top-0 z-1 bg-ld-background px-4"
      @select="onTagSelect"
    />

    <PkgCardList
      :packages="data.searchedPackages"
      class="p-4"
      highlight
      @pkgClick="pkg => emit('pkgClick', pkg)"
    />

    <Loading v-if="data.loading"></Loading>
  </div>
</template>
<script setup lang="ts">
import {
  clearSearchKeywordLogs,
  fetchSearchKeywordLogs,
  searchPackageTags,
  type ContentTagWithParent,
} from '@/api/package-source'
import { reactive, ref } from 'vue'

import { searchPackages, type SearchedPackage } from '@/api/package-source'

import PkgCardList from './PkgCardList.vue'
import RecommendPkgList from '../RecommendPkgList.vue'
import { useCommonStore } from '@/stores'
import { openPackageCreateDialog, openPackageGetSheet } from '@/shared'
import { useRouter } from 'vue-router'
import { useInfiniteScroll } from '@vueuse/core'
import ContentTagList from '@/components/ContentTagList.vue'

const store = useCommonStore()
const router = useRouter()

const props = defineProps<{
  // 路由方式，不同的路由方式导航逻辑会有差异
  // 参考：https://www.figma.com/design/sNi7fnbw3njn4pmRyoRkmr/Cloze-App?node-id=23073-26761&node-type=canvas&t=IQ0QbrSpmMy2MImC-0
  navigator?: 'modal' | 'bottomSheet'
}>()

const emit = defineEmits<{
  back: []
  pkgClick: [SearchedPackage]
}>()

const data = reactive({
  loading: false,
  keyword: '',
  searchLogs: [] as string[],

  // null 表示还没有进行搜索
  selectedTagKey: undefined as string | undefined,
  contentTags: [] as ContentTagWithParent[],
  searchedPackages: null as SearchedPackage[] | null,
  offset: 0,
  limit: 50,
  noMoreData: false,
})

const recommendContainer = ref<HTMLDivElement>()
const searchContainer = ref<HTMLDivElement>()
useInfiniteScroll(searchContainer, () => handleSearch(), {
  distance: 20,
  interval: 300,
  canLoadMore: () => !data.noMoreData,
})

fetchSearchKeywordLogs().then(({ logs }) => {
  data.searchLogs = logs.map(({ keyword }) => keyword)
})

function onSearchLogsClear() {
  data.searchLogs = []

  clearSearchKeywordLogs().then(() => {
    _message.success('搜索历史已清除')
  })
}

function onKeywordClear() {
  data.keyword = ''

  resetSearchParams()
  data.searchedPackages = null
}

function onKeywordLogSearch(keyword: string) {
  data.keyword = keyword

  resetSearchParams()
  handleSearch(true)
}

function onBack() {
  if (data.searchedPackages != null) {
    data.keyword = ''
    data.searchedPackages = null
    return
  }

  emit('back')
}

function onSearchByEnter(evt: KeyboardEvent) {
  if (evt?.isComposing) return
  data.keyword = data.keyword.trim()

  resetSearchParams()
  handleSearch(true)
}

function resetSearchParams() {
  data.offset = 0
  data.selectedTagKey = undefined
}

// reset: 表示新的搜索结果是否要覆盖当前的数据
// 如果直接在上层先清空再获取的时候会出现短暂的空状态，所以在搜索的时候处理
async function handleSearch(reset?: boolean) {
  if (data.keyword === '' || data.loading) return

  data.loading = true

  try {
    searchPackageTags(data.keyword.trim()).then(res => {
      data.contentTags = res.contentTags
    })

    const res = await searchPackages({
      keyword: data.keyword,
      offset: data.offset,
      limit: data.limit,
      contentTagKey: data.selectedTagKey,
    })

    if (data.searchedPackages == null || reset) {
      data.searchedPackages = res.packages
    } else {
      data.searchedPackages.push(...res.packages)
    }

    if (data.searchedPackages.length >= res.total) {
      data.noMoreData = true
      data.loading = false
      return
    }

    data.offset = res.offset + res.limit
  } finally {
    data.loading = false
  }
}

function onTagSelect(tagKey?: string) {
  data.noMoreData = false
  data.selectedTagKey = tagKey
  data.offset = 0

  handleSearch(true)
}

function onScrollToTop() {
  const recommendList = document.querySelector('.recommend-list') as any
  if (recommendList) {
    recommendContainer.value?.scrollTo({
      top: recommendList.offsetTop - 48,
    })
  }
}

function onPackageGet() {
  openPackageGetSheet({
    onCreate() {
      openPackageCreateDialog({
        onCreate(pkg) {
          router.push({ name: 'package', params: { id: pkg.id } })
        },
      })
    },
  })
}
</script>
<style scoped>
.search-bar {
  border: 1px solid var(--ld-border);
  border-radius: 4px;
  padding: 0 8px;
}

.p-inputtext {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: unset;
}

.keyword-log {
  cursor: pointer;
  color: var(--text-color-secondary);
  background-color: var(--surface-200);
  border-radius: 4px;
  font-size: 13px;
  padding: 4px 8px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  word-break: break-all;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}
</style>
