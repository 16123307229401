import { createRouter, createWebHistory } from 'vue-router'

import PackageBrowser from './pages/Package/Package.vue'
import Shelf from '@/pages/Shelf.vue'
import Mine from '@/pages/Mine.vue'

import WxCallback from './pages/WxCallback.vue'
import MobileWxCallback from '@/mobile/pages/WxCallback.vue'
import Auth from './pages/Auth.vue'
import AuthInApp from '@/pages/in-app/Auth.vue'
import App from './App.vue'
import IdentityOnboarding from '@/components/IdentityOnboarding/IdentityOnboarding.vue'
import LandingInApp from '@/pages/in-app/Landing.vue'
import Landing from '@/pc/pages/Landing.vue'
import UnitLearn from '../pages/UnitLearn/UnitLearn.vue'
import PublicPackage from '@/pages/PublicPackage.vue'
import Atlas from '@/pages/Atlas/Atlas.vue'
import type { RouteRecordRaw } from 'vue-router'
import Message from '@/pages/Message.vue'
import Reward from '@/pc/pages/Reward.vue'
import Rank from '@/pages/Rank/Rank.vue'
import NotFound from '@/pages/NotFound.vue'
import AppDownload from '@/pc/pages/AppDownload.vue'
import {
  LANDING_PATH,
  UNIT_LEARN_PATH,
  WX_CALLBACK_PATH,
  debugRoute,
  setupHistoryMgmt,
} from '@/shared'
import BetaFeature from '@/pages/BetaFeature/BetaFeature.vue'
import Terminating from '@/pages/Terminating.vue'
import Support from '@/pages/Support.vue'
import AboutUs from '@/pages/in-app/AboutUs.vue'

// route
// - name format: camel case, use '/' to divide, e.g. 'login', 'publicPackage', 'unit/learn'
// - App 里的组件默认都是需要鉴权的
// - meta
//  - fullscreen: boolean 不需要 App Layout
const routes: RouteRecordRaw[] = [
  {
    path: WX_CALLBACK_PATH,
    component: MobileWxCallback,
    name: 'wxcallback',
  },
  {
    path: '/wxcallback-pc',
    component: WxCallback,
    name: 'wxcallback-pc',
  },
  {
    path: '/app-download',
    component: AppDownload,
    name: 'app-download',
  },
  {
    path: '/beta-feature',
    name: 'beta-feature',
    component: BetaFeature,
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/auth',
    component: _global.isInsideApp ? AuthInApp : Auth,
    name: 'auth',
  },
  {
    path: '/identity-onboarding',
    component: IdentityOnboarding,
    name: 'identity-onboarding',
  },
  {
    path: LANDING_PATH,
    component: _global.isInsideApp ? LandingInApp : Landing,
    name: 'landing',
  },
  {
    path: '/pkgs/:hashId',
    component: PublicPackage,
    name: 'publicPackage',
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
  },
  {
    path: '/support/:productId',
    component: Support,
    name: 'support',
  },
  {
    path: '/about-us',
    component: AboutUs,
    name: 'about-us',
  },
  {
    path: '/',
    component: App,
    children: [
      { path: '', redirect: 'landing' },
      {
        path: 'atlas',
        component: Atlas,
        name: 'atlas',
        meta: {
          keepAlive: true,
        },
      },

      {
        path: 'shelf',
        component: Shelf,
        name: 'shelf',
      },
      {
        path: 'mine',
        component: Mine,
        name: 'mine',
      },
      {
        path: '/message',
        component: Message,
        name: 'message',
      },
      {
        path: '/reward',
        component: Reward,
        name: 'reward',
      },
      {
        path: 'rank',
        component: Rank,
        name: 'rank',
      },
      {
        path: UNIT_LEARN_PATH,
        component: UnitLearn,
        name: 'unit/learn',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/packages/:id',
        component: PackageBrowser,
        name: 'package',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/terminating',
        component: Terminating,
        name: 'terminating',
        meta: {
          fullscreen: true,
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
if (!_global.isProd) {
  routes.push(debugRoute)
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

setupHistoryMgmt(router)

router.beforeEach((to, _from) => {
  const needAuth = to.matched[0].path === '/' || to.meta.needAuth

  if (needAuth && !_store.isLoggedIn) {
    return {
      name: 'landing',
      query: {
        redirect: encodeURIComponent(to.fullPath),
      },
    }
  }
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    _closeAllDialogs()
  }
})

export default router
