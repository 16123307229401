// this file is the very first file to run
// we use this file to init _global and store something
// that can be used by all other files
import db from './db'
import am from '@/assets-manager'
import { showErrorDialog } from './debug'

export type Env = 'local' | 'staging' | 'prod' | 'gray'
export type ApiEnv = 'staging' | 'prod'

const OSS_URL_STAGING = 'https://cdn.lingduck.top'
const OSS_URL_PROD = 'https://cdn.lingduck.cn'

// 不直接暴露，使用相关的方法去访问
const env = parseEnv(location.hostname)

window.onInit = (cb: () => void) => cb()
window._t = str => str
function getDebugHide() {
  return new URLSearchParams(location.search).get('hideDebug') === 'true'
}
window._am = am

window._global = {
  unreachable(extra?: any) {
    const err = new Error('unreachble') as any
    err.extra = extra
    showErrorDialog('unreachable error', extra)

    throw err
  },

  assert(expr: boolean, msg: string, extra?: any) {
    if (!expr) {
      const err = new Error('assert error: ' + msg) as any
      err.extra = extra
      showErrorDialog(`assert failed: ${msg}`, extra)
      throw err
    }
  },

  sentryEnvironment: env,

  get isLocal() {
    return env === 'local'
  },
  get isStaging() {
    return env === 'staging'
  },
  get isProd() {
    return env === 'prod' || env === 'gray'
  },

  aliyunCaptchaSceneId: 'n9j3ojpv',
  aliyunCaptchaPrefix: '1ynvpx',

  get apiEnv() {
    if (this.isProd) {
      return 'prod'
    }

    return db.debug.apiEnv
  },

  // 是否在非 prod 环境连接 prod api
  get isDebugProd() {
    return this.apiEnv === 'prod' && !this.isProd
  },

  // 某些服务配置了固定跳转到生产地址，例如微信登录
  // 生产网关增加了特殊配置，从而使得其他环境也可用
  get prodGatewayPrefix(): string {
    return {
      local: '/__local__',
      staging: '/__staging__',
      gray: '/__gray__',
      prod: '',
    }[env]
  },

  // LingDuck 帮助文档
  helpLink: 'https://help.lingduck.cn',
  // 隐私政策
  privacyLink: 'https://help.lingduck.cn/terms/privacy.html',
  // 用户协议
  userLink: 'https://help.lingduck.cn/terms/user.html',
  // 联系我们
  contactLink: 'https://help.lingduck.cn/about/contact.html',
  // 畅学卡服务协议
  cardServiceLink: 'https://help.lingduck.cn/terms/vip.html',
  // 自动续费服务规则
  autoRenewLink: 'https://help.lingduck.cn/terms/renew.html',
  // iOS 小组件
  iosWidgetLink: 'https://help.lingduck.cn/features/widget_ios.html',
  // android 小组件
  androidWidgetLink: 'https://help.lingduck.cn/features/widget_android.html',

  bilibiliLink: 'https://space.bilibili.com/2006586621',
  xhsLink: 'https://www.xiaohongshu.com/user/profile/654b4341000000000301d1a4',

  get pcLink(): string {
    return this.isProd ? 'www.lingduck.cn' : 'www.lingduck.top'
  },

  homePage: {
    name: 'atlas',
  },

  // 卡包可以学习的最小卡片数量
  minCardCountToLearn: 10,

  // 兔小巢
  txcProductId: 669589,

  gitCommit: __GIT_COMMIT__,

  appMode: __APP_MODE__,
  get isPcMode() {
    return this.appMode === 'pc'
  },
  get isMobileMode() {
    return this.appMode === 'mobile'
  },

  isMac: /(Macintosh|iPad)/i.test(navigator.userAgent),

  // 是否隐藏调试信息, 目前 app 通过 url 参数来控制
  hideDebug: getDebugHide(),

  now(): Date {
    if (_store.debugTs) {
      return new Date(_store.debugTs)
    }

    return new Date()
  },

  get isInsideWechat() {
    return /MicroMessenger/i.test(navigator.userAgent)
  },

  get isInsideApp() {
    return !!window.flutter_inappwebview
  },

  get clientType() {
    return _global.isInsideApp ? 'app' : _global.isPcMode ? 'pc-web' : 'm-web'
  },
  get clientChannel() {
    return _global.isInsideApp
      ? _store.appInfo?.channel
      : _global.isInsideWechat
        ? 'wechat'
        : 'normal'
  },
  get clientVersion() {
    return _global.isInsideApp ? _store.appInfo?.fullVersion : _global.gitCommit
  },

  // 微信开放平台，网站应用
  wxAppId: 'wxe2e0a1b8eb6b4c08',

  assetUrl(assetId, imgStyle?: string) {
    const ossURL = this.apiEnv === 'prod' ? OSS_URL_PROD : OSS_URL_STAGING
    const url = `${ossURL}/${assetId}`

    if (imgStyle) {
      return url + `?x-oss-process=style/${imgStyle}`
    }

    return url
  },
}

function parseEnv(host: string): Env {
  switch (host) {
    case 'localhost':
      return 'local'

    case 'lingduck.top':
    case 'm.lingduck.top':
      return 'staging'

    case 'gray.lingduck.top':
    case 'm.gray.lingduck.top':
      return 'gray'

    case 'lingduck.cn':
    case 'm.lingduck.cn':
      return 'prod'

    default:
      if (location.port !== '') return 'local'
      return 'prod'
  }
}

_am.preload([
  { type: 'audio', name: 'compress-monster_cry' },
  { type: 'audio', name: 'public_hit' },
  { type: 'audio', name: 'public_correct' },
  { type: 'audio', name: 'energy_gain' },
  { type: 'audio', name: 'diamond_gain' },
])
