<template>
  <div class="h-ld-screen flex flex-col bg-ld-background">
    <RatioSpacedContainer class="flex-1 flex flex-col">
      <div class="w-full flex flex-col items-center justify-center flex-1">
        <Icon
          name="ld-avatar"
          class="w-92px"
        ></Icon>

        <div class="flex flex-col items-center py-3">
          <div
            class="text-21px text-ld-brand-500 font-semibold leading-[1.8] tracking-0.5px"
          >
            {{ _t('小灵鸭') }}
          </div>

          <div class="text-17px leading-[1.8] tracking-0.5px">
            {{ _t('闯关玩游戏，学习零压力') }}
          </div>
        </div>
      </div>

      <div
        class="mt-6 p-6 rounded-12px bg-white border-1px border-solid border-gray-200"
      >
        <Img
          :name="appDownloadQrcodeImg"
          class="w-245px"
        />

        <div class="mt-5 text-17px leading-[1.5] tracking-0.5px text-center">
          <div>{{ _t('手机扫码，或前往各应用市场') }}</div>
          <div>{{ _t('搜索“小灵鸭”App') }}</div>
        </div>
      </div>
    </RatioSpacedContainer>

    <CopyrightFooter class="mx-auto mt-auto mb-8" />
  </div>
</template>
<script setup lang="ts">
import CopyrightFooter from '@/components/CopyrightFooter.vue'
import { computed } from 'vue'
import { useHead } from '@unhead/vue'

useHead({
  title: _t('打开小灵鸭'),
  meta: [
    {
      name: 'description',
      content: _t('闯关玩游戏，学习零压力'),
    },
  ],
})

const appDownloadQrcodeImg = computed(
  () => `app-download-link-${_global.isProd ? 'prod' : 'staging'}`
)
</script>
<style scoped></style>
