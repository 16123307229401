<template>
  <div class="overflow-hidden h-full bg-ld-background relative">
    <div class="flex flex-col h-full">
      <SearchPage
        :navigator="props.navigator"
        @back="onBack"
        @pkgClick="onPkgCardClick"
      />
    </div>

    <div
      v-for="(page, i) in pageFrames"
      :key="i"
      class="absolute top-0 left-0 z-1 w-full h-full flex flex-col bg-ld-background overflow-hidden"
    >
      <component :is="page" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { h, ref } from 'vue'
import SearchPage from './SearchPage.vue'
import TextButton from '@/components/TextButton.vue'

import type { Component, VNode } from 'vue'
import type { SearchedPackage } from '@/api/package-source'
import PublicPackage from '@/components/PublicPackage.vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  // 路由方式，不同的路由方式导航逻辑会有差异
  // 参考：https://www.figma.com/design/sNi7fnbw3njn4pmRyoRkmr/Cloze-App?node-id=23073-26761&node-type=canvas&t=IQ0QbrSpmMy2MImC-0
  // page 单独在 mobile 页面处理
  navigator: 'modal' | 'bottomSheet'
}>()

const router = useRouter()

const emit = defineEmits<{
  packagesUpdate: [pkgId?: number]
  packageChallenge: [pkgId: number]
  done: []
}>()

const pageFrames = ref<VNode[]>([])

function onBack() {
  if (pageFrames.value.length > 0) {
    pageFrames.value.pop()
  } else {
    emit('done')
  }
}

function onPkgCardClick(pkg: SearchedPackage) {
  let actionsSlot: Component | undefined = undefined

  if (props.navigator === 'modal') {
    actionsSlot = h('i', {
      class: 'pi pi-times cursor-pointer ml-4',
      onClick() {
        emit('done')
      },
    })
  } else if (props.navigator === 'bottomSheet') {
    actionsSlot = h(TextButton, {
      label: _t('完成'),
      class: 'ml-4',
      onClick() {
        emit('done')
      },
    })
  }

  pageFrames.value.push(
    h(
      PublicPackage,
      {
        class: 'flex-1 overflow-hidden pt-2',
        hashId: pkg.hashId,
        showBackBtn: true,
        onBack,
        onView(pkgId: number) {
          router.push({
            name: 'package',
            params: {
              id: pkgId,
            },
          })
        },
        onChallenge(pkgId: number) {
          emit('packageChallenge', pkgId)
        },
        onAddToShelf(pkgId: number) {
          emit('packagesUpdate', pkgId)
        },
        onRemoveFromShelf() {
          emit('packagesUpdate')
        },
      },
      {
        actions: () => actionsSlot,
      }
    )
  )
}
</script>
<style scoped></style>
