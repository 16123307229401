<template>
  <EnWordCardLayout :hide-role-image="props.face.style.hideRoleImage">
    <template #content>
      <DebutCardBadge
        v-if="face.isFirstLearn"
        class="absolute top-0 left-0"
      />
      <LottieBox
        ref="waveLottie"
        name="wave"
        class="h-full cursor-pointer my--10"
        :autoplay="false"
        loop
        @click="onWordPlay"
      />
    </template>

    <template #options>
      <div class="flex flex-col gap-3">
        <Button
          v-for="(item, i) of options"
          :key="item.word"
          :scene="getOptionScene(item, i)"
          class="option"
          :class="{ wrong: optionsWrongMap[i] }"
          @click="onOptionClick(item, i)"
        >
          <SparkleText
            class="flex flex-col justify-center items-start w-full text-start"
            :tag="item.isAnswer && data.checked"
          >
            <template v-if="optionsWrongMap[i]">
              <div class="text-lg font-semibold leading-26px">
                {{ item.word }}
              </div>
              <div
                class="text-[var(--text-color-secondary)] text-sm leading-22px"
              >
                {{ item.definition }}
              </div>
            </template>
            <div
              v-else
              class="text-25px"
            >
              {{ item.word }}
            </div>
          </SparkleText>
        </Button>
      </div>
    </template>
  </EnWordCardLayout>
</template>
<script setup lang="ts">
import { UnitEventType, type VoiceChoiceEnWordFace } from '@/types/core'
import { reactive, computed, inject, ref, onMounted } from 'vue'
import { genOptions, type Option } from '../common'
import { useHotKey } from '@/hooks'
import { FeedbackStar } from '../../common/feedback'
import SparkleText from '@/components/SparkleText.vue'
import EnWordCardLayout from '../../layout/EnWordCardLayout.vue'
import { wait } from '@/utils'
import DebutCardBadge from '@/components/ConcreteCard/common/DebutCardBadge.vue'
import { showCardDetail } from '../../common'
import LottieBox from '@/components/LottieBox.vue'

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index >= options.value.length) return

  const op = options.value[index]
  onOptionClick(op, index)
})

const onNext = inject<VoidFunction>('onNext')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onStar = inject<(star: FeedbackStar) => void>('onStar')

const props = defineProps<{
  face: VoiceChoiceEnWordFace
}>()

const data = reactive({
  checked: false,
  tipTimes: 0,
  star: FeedbackStar.One,
  selectedWrongOptions: new Set<number>(),
})

const options = computed(() => genOptions(props.face.card, props.face.altCards))

const optionsWrongMap = computed(() => {
  const map: Record<number, boolean> = {}

  for (let i = 0; i < options.value.length; i++) {
    map[i] = data.selectedWrongOptions.has(i)
  }

  return map
})

function getOptionScene(
  op: Option,
  index: number
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  const wrong = optionsWrongMap.value[index]
  const correct = op.isAnswer && data.checked

  if (correct) {
    return 'choiceCorrect'
  }
  if (wrong) {
    return 'choiceWrong'
  }

  return 'choice'
}

async function onAutoNext(beforeNext: Promise<void>) {
  // 先等待按钮动画结束
  await wait(700)

  // 如果是新知识且设置的展示卡片详情，此时会先弹出卡片详情
  if (props.face.isFirstLearn && props.face.style.showCardDetail) {
    showCardDetail(props.face.card, props.face.isFirstLearn, onNext)
    return
  }

  try {
    await beforeNext
  } finally {
    onNext?.()
  }
}

async function onOptionClick(option: Option, index: number) {
  if (data.checked) return

  if (option.isAnswer) {
    data.checked = true
    onEvent?.(UnitEventType.CORRECT)
  } else {
    data.tipTimes++
    data.selectedWrongOptions.add(index)
    onEvent?.(UnitEventType.WRONG)
  }

  data.star = data.tipTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three

  if (data.checked) {
    onStar?.(data.star)
  }

  if (data.checked) {
    onAutoNext(_am.playPron(option.word))
  } else {
    _am.playPron(option.word)
  }
}

const waveLottie = ref<any>()
function onWordPlay() {
  waveLottie.value?.play()
  _am.playPron(props.face.card.word).then(() => {
    waveLottie.value?.pause()
  })
}

onMounted(async () => {
  await _am.preload([{ type: 'pron', text: props.face.card.word }])
  onWordPlay()
})
</script>

<style scoped>
.option {
  min-height: 66px;
  position: relative;
}
.option.wrong {
  animation: shake 0.2s linear 0.2s;
}
</style>
