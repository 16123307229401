<template>
  <Message
    v-if="!isValidData"
    :closable="false"
    severity="error"
  >
    {{ _t('卡片数据暂不支持选图卡面') }}
  </Message>

  <template v-else>
    <EnWordCardLayout :hide-role-image="props.face.style.hideRoleImage">
      <template #content>
        <DebutCardBadge
          v-if="face.isFirstLearn"
          class="absolute top-0 left-0"
        />

        <div class="text-primary font-semibold text-4xl leading-none mb-2">
          {{ props.face.card.word }}
        </div>

        <div
          v-if="usPron"
          class="text-17px leading-normal text-[var(--text-color-secondary)] flex justify-center items-center cursor-pointer"
          @click="onPronClick"
        >
          {{ usPron.label }}
          <div class="p-4px">
            <Icon
              name="pron"
              class="w-16px h-16px text-ld-brand-600"
            />
          </div>
        </div>
      </template>
      <template #options>
        <div
          ref="optionContainer"
          class="grid grid-cols-2 gap-3 mt-4 max-w-560px mx-auto"
        >
          <Button
            v-for="item of options"
            :key="item.word"
            class="option"
            :scene="getOptionScene(item)"
            :style="`height: ${data.maxOptionHeight}px`"
            :class="[
              {
                wrong: data.selectedWrongOptions.has(item),
                correct: item.isAnswer && data.checked,
              },
            ]"
            @click="onOptionClick(item)"
          >
            <SparkleText
              class="flex flex-col justify-center items-start w-full"
              :tag="data.checked && item.isAnswer"
            >
              <div class="flex-1 flex items-center w-full">
                <img
                  v-if="item.illustration"
                  :src="_global.assetUrl(item.illustration)"
                  @load="onImageLoad"
                />
              </div>

              <span class="truncate text-sm text-center mt-2 w-full">
                {{ item.definition }}
              </span>
            </SparkleText>
          </Button>
        </div>
      </template>
    </EnWordCardLayout>
  </template>
</template>
<script setup lang="ts">
import { type PickImageEnWordFace, UnitEventType } from '@/types/core'
import { reactive, computed, onMounted, inject, ref } from 'vue'
import { genWordPickOptions, type Option } from '../common'
import { useHotKey } from '@/hooks'
import { FeedbackStar } from '../../common/feedback'
import SparkleText from '@/components/SparkleText.vue'
import EnWordCardLayout from '../../layout/EnWordCardLayout.vue'
import { wait } from '@/utils'
import DebutCardBadge from '@/components/ConcreteCard/common/DebutCardBadge.vue'
import { showCardDetail } from '../../common'

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index >= options.value.length) return

  const op = options.value[index]
  onOptionClick(op)
})

const onNext = inject<VoidFunction>('onNext')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onStar = inject<(star: FeedbackStar) => void>('onStar')

const props = defineProps<{
  face: PickImageEnWordFace
}>()

const data = reactive({
  checked: false,
  tipTimes: 0,
  star: FeedbackStar.One,
  selectedWrongOptions: new Set<Option>(),
  maxOptionHeight: null as number | null,
})

const optionContainer = ref<HTMLDivElement>()

// 选图卡面需要判断数据是否有效
const isValidData = computed(() => {
  if (!props.face.card.illustration) return false

  if (props.face.altCards.length === 0) return false

  return true
})

const usPron = computed(() => {
  const us = props.face.card.prons.find(item => item.language === 'en-US')
  return us
})

const options = computed(() =>
  genWordPickOptions(props.face.card, props.face.altCards)
)

onMounted(() => {
  _am.playPron(props.face.card.word)
})

function animeOptionWrong(op: Option) {
  data.selectedWrongOptions.add(op)
  setTimeout(() => {
    data.selectedWrongOptions.delete(op)
  }, 400)
}

async function onAutoNext(beforeNext: Promise<void>) {
  // 先等待按钮动画结束
  await wait(700)

  // 如果是新知识且设置的展示卡片详情，此时会先弹出卡片详情
  if (props.face.isFirstLearn && props.face.style.showCardDetail) {
    showCardDetail(props.face.card, props.face.isFirstLearn, onNext)
    return
  }

  try {
    await beforeNext
  } finally {
    onNext?.()
  }
}

async function onOptionClick(option: Option) {
  if (data.checked) return

  if (option.isAnswer) {
    data.checked = true
    onEvent?.(UnitEventType.CORRECT)
  } else {
    data.tipTimes++
    animeOptionWrong(option)
    onEvent?.(UnitEventType.WRONG)
  }

  data.star = data.tipTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three

  if (data.checked) {
    onStar?.(data.star)
  }

  if (data.checked) {
    onAutoNext(_am.playPron(option.word))
  } else {
    _am.playPron(option.word)
  }
}

function onPronClick() {
  _am.playPron(props.face.card.word)
}

let loadedImageCount = 0
function onImageLoad() {
  loadedImageCount++

  if (loadedImageCount < options.value.length) {
    return
  }

  if (optionContainer.value == null) return

  const optionEls = optionContainer.value.querySelectorAll('.option')

  for (const op of optionEls) {
    if (
      data.maxOptionHeight == null ||
      op.clientHeight > data.maxOptionHeight
    ) {
      data.maxOptionHeight = op.clientHeight
    }
  }
}
function getOptionScene(
  op: Option
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  let wrong = data.selectedWrongOptions.has(op)
  let correct = op.isAnswer && data.checked

  if (correct) {
    return 'choiceCorrect'
  }
  if (wrong) {
    return 'choiceWrong'
  }

  return 'choice'
}
</script>

<style scoped>
.option {
  height: 100%;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.option img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.option.wrong {
  animation: shake 0.2s linear 0.2s;
}
</style>
