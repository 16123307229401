<template>
  <Loading v-if="data.registerLoading" />

  <WxQRCode
    v-else
    @code="onWxCallback"
  />
</template>

<script lang="ts" setup>
import { wxLogin, wxRegister, type UserInfo } from '@/api/auth'
import { reactive } from 'vue'
import WxQRCode from './WxQRCode.vue'

const data = reactive({
  registerLoading: false,
})

const emit = defineEmits<{
  done: [token: string, user: UserInfo]
}>()

function onWxCallback(code: string) {
  wxLogin({ code }).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    if (res.data.loginResponse) {
      const { token, user } = res.data.loginResponse
      emit('done', token, user)
    } else {
      handleRegister(res.data.wxToken)
    }
  })
}

function handleRegister(wxToken: string) {
  data.registerLoading = true

  wxRegister({ wxToken })
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      emit('done', res.data.token, res.data.user)
    })
    .finally(() => {
      data.registerLoading = false
    })
}
</script>

<style scoped></style>
