<template>
  <div class="flex flex-col gap-4 items-center">
    <Icon
      name="logo"
      class="w-58px mb-30px"
    />

    <template
      v-for="tab in tabs"
      :key="tab.label"
    >
      <div
        v-if="tab.spaceTop"
        class="flex-1"
      ></div>

      <div
        class="flex flex-col items-center justify-center cursor-pointer w-84px h-68px text-gray-500"
        :class="{
          selected: isActivedRoute(tab.route),
          'text-ld-brand-500': isActivedRoute(tab.route),
        }"
        @click="onTabClick(tab)"
      >
        <div class="w-8 h-8 relative">
          <Badge
            v-if="tab.badge != null"
            :value="tab.badge"
            class="top--8px left-20px"
          />

          <Icon
            v-if="isActivedRoute(tab.route)"
            :name="tab.activeIcon"
          />
          <Icon
            v-else
            :name="tab.icon"
          />
        </div>

        {{ tab.label }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import Icon from './Icon.vue'
import Badge from './Badge.vue'
import { useCommonStore } from '@/stores'
import { computed } from 'vue'
import { NavIcons } from '@/shared/icons'
import { openHelpLink } from '@/utils'

const route = useRoute()
const router = useRouter()
const store = useCommonStore()

interface HomeTab {
  label: string
  icon: string
  activeIcon: string
  route: string
  spaceTop?: boolean
  badge?: number | string
}
const tabs = computed<HomeTab[]>(() => [
  {
    label: NavIcons.atlas.label,
    icon: NavIcons.atlas.icon,
    activeIcon: NavIcons.atlas.activeIcon,
    route: 'atlas',
  },
  {
    label: NavIcons.reward.label,
    icon: NavIcons.reward.icon,
    activeIcon: NavIcons.reward.activeIcon,
    route: 'reward',
    badge: store.unreceivedTaskCount,
  },
  {
    label: NavIcons.rank.label,
    icon: NavIcons.rank.icon,
    activeIcon: NavIcons.rank.activeIcon,
    route: 'rank',
    badge: store.showRankGuide ? 'i' : '',
  },
  {
    label: NavIcons.bookshelf.label,
    icon: NavIcons.bookshelf.icon,
    activeIcon: NavIcons.bookshelf.activeIcon,
    route: 'shelf',
  },
  {
    label: NavIcons.mine.label,
    icon: NavIcons.mine.icon,
    activeIcon: NavIcons.mine.activeIcon,
    route: 'mine',
  },
  {
    label: '消息',
    icon: 'action-message',
    activeIcon: 'action-message-active',
    route: 'message',
    spaceTop: true,
    badge: store.unreadMsgCount,
  },
  {
    label: '问题反馈',
    icon: 'action-help',
    activeIcon: 'action-help-active',
    route: 'help',
  },
])

function onTabClick(tab: HomeTab) {
  // 帮助页面单独处理 跳转到兔小巢
  if (tab.route === 'help') {
    openHelpLink(store.user, store.vip)
  } else {
    router.push({
      name: tab.route,
    })
  }
}

function isActivedRoute(routeName: string) {
  return ((route.name ?? '') as string).startsWith(routeName)
}
</script>

<style scoped>
.selected {
  border: 1px solid var(--ld-border);
  box-shadow: 0px 2px 4px 0px var(--slate-200);
  border-radius: 8px;
}
</style>
