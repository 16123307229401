<template>
  <div class="pt-2 overflow-hidden flex flex-col h-full bg-ld-background">
    <PublicPackage
      v-if="currentPkg"
      class="flex-1 overflow-hidden"
      :pkg="currentPkg"
      :hashId="currentPkg.hashId"
      showBackBtn
      @back="currentPkg = null"
      @challenge="emit('packageChallenge', $event)"
    />

    <div
      v-show="!currentPkg"
      class="flex-1 flex flex-col overflow-hidden"
    >
      <div class="flex items-enter mt-2">
        <span class="text-19px font-600 mx-auto">
          {{ _t('卡包推荐') }}
        </span>

        <Icon
          name="close-circle"
          class="cursor-pointer absolute right-4 z-1 w-25px"
          @click="emit('done')"
        ></Icon>
      </div>

      <div
        ref="el"
        class="flex-1 overflow-y-auto"
      >
        <div class="flex greet justify-center items-center">
          <Img name="ld-greet" />
          <div
            ref="chatBox"
            class="chat"
            @animationend="onChatBoxAnimationEnd"
          ></div>
        </div>

        <RecommendPkgList
          :show="showPackages"
          :scroll-container="el"
          class="px-2 flex-1"
          @scrollToTop="onScrollToTop"
          @pkgClick="onPkgClick"
        ></RecommendPkgList>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SearchedPackage } from '@/api/package-source'
import PublicPackage from '@/components/PublicPackage.vue'
import RecommendPkgList from '@/components/RecommendPkgList.vue'
import Typewriter from 'typewriter-effect/dist/core'
import { ref } from 'vue'

const currentPkg = ref<SearchedPackage | null>(null)

const emit = defineEmits<{
  packageChallenge: [pkgId: number]
  done: []
}>()

const el = ref<HTMLDivElement>()

function onPkgClick(pkg: SearchedPackage) {
  currentPkg.value = pkg
}

const showPackages = ref(false)
const chatBox = ref<HTMLDivElement>()
function onChatBoxAnimationEnd() {
  const typewriter = new Typewriter(chatBox.value, {
    delay: 15,
    cursor: '',
  })

  typewriter
    .typeString('为你挑选了一些适合的卡包，选择一个你喜欢的开始吧！')
    .pauseFor(200)
    .start()
    .callFunction(() => {
      setTimeout(() => {
        showPackages.value = true
      }, 200)
    })
}

function onScrollToTop() {
  const scrollTop = el.value?.scrollTop ?? 0
  const greet = document.querySelector('.greet') as HTMLDivElement

  if (el.value != null && greet != null) {
    const greetHeight = greet.clientHeight

    // 因为切换 tag 的时候，卡包的内容不一样了，所以需要滚动到第一个卡包的位置
    // 如果滚动的距离没有把灵鸭的 icon 给盖住，那么就不需要滚动，此时标签列表还没有吸顶
    // 如果超过了，那么只需要滚动到刚好吸顶，也就是整个灵鸭 icon 的高度就可以了
    if (scrollTop > greetHeight) {
      el.value?.scrollTo({
        top: greetHeight,
      })
    }
  }
}
</script>

<style scoped>
.greet :deep(img) {
  width: 77px;
}

.greet .chat {
  background-color: white;
  border-radius: 18px;
  font-size: 17px;
  line-height: 22px;
  padding: 12px 16px;
  height: fit-content;
  animation: chatScale 0.15s;
  animation-fill-mode: forwards;
  white-space: nowrap;
}

@keyframes chatScale {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    height: 72px;
    width: 260px;
  }
}
</style>
