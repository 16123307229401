import '../init'

import EntryWrapper from '../EntryWrapper.vue'
import Entry from './Entry.vue'
import db from '../db'
import { createMyApp } from '@/app'

import { loadLocaleMessages } from '../i18n'
import { subscribeStore, useCommonStore } from '../stores/common-store'
import router from './router'

import 'virtual:uno.css'
import './global.css'
import { setViewportHeight } from '../utils'
import 'animate.css'
import { fastClick } from '@/directives'
import { afterAppMount, initPlausible } from '@/shared'
import bridge from '@/utils/bridge'
import { nextTick } from 'vue'

const app = createMyApp(EntryWrapper, { entry: Entry })

loadLocaleMessages(db.locale).then(init)

async function init() {
  const store = useCommonStore()
  subscribeStore(store)

  // global variables
  window._store = store
  window._db = db
  window._router = router
  window._bridge = bridge
  window._app = app

  app.use(router)
  app.directive('fast-click', fastClick)

  if (_global.isInsideApp) {
    _bridge.webReady()
    await store.getAppInfo()
  }

  // init app
  app.mount('#app')
  nextTick(afterAppMount)

  initPlausible()
}

setViewportHeight()
window.addEventListener('resize', setViewportHeight)
