<template>
  <!-- 禁用 naive-ui 的全局样式，参考 https://www.naiveui.com/zh-CN/os-theme/docs/style-conflict  -->
  <n-config-provider preflight-style-disabled>
    <DynamicDialog />

    <DebugFloatingBall />

    <div
      ref="entryRef"
      class="g-h-screen"
    >
      <Toast
        position="top-center"
        group="tc"
      />

      <RouterView />
    </div>
  </n-config-provider>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { h, onMounted, onUnmounted, ref } from 'vue'
import DynamicDialog from 'primevue/dynamicdialog'
import { useDialog } from 'primevue/usedialog'
import { NConfigProvider } from 'naive-ui'

import Confirm from '@/components/Confirm.vue'
import Notice from '@/components/Notice.vue'
import InputText from 'primevue/inputtext'
import Button from '@/components/Button.vue'
import { useHotKey } from '@/hooks/index'
import DebugFloatingBall from '@/components/DebugFloatingBall.vue'
import NotEnoughDiamonds from '@/components/NotEnoughDiamonds.vue'

import type { Component, Ref } from 'vue'
import type { DialogProps } from 'primevue/dialog'
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import type {
  OpenDialogPromise,
  InputDialogOptions,
  ConfirmResolveCallback,
} from '@/types/global'
import RewardDialog from '@/components/RewardDialog.vue'
import type { Reward } from '@/api/task'
import zindexMgmt from '@/utils/zindexMgmt'

const toast = useToast()

const entrySize = ref({ width: 0, height: 0 })
const entryRef = ref<HTMLDivElement>()

function onWindowResize() {
  entrySize.value.width = entryRef.value?.clientWidth ?? 0
  entrySize.value.height = entryRef.value?.clientHeight ?? 0
}

window._viewSize = entrySize

onMounted(() => {
  onWindowResize()
  window.addEventListener('resize', onWindowResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
})

const messageFn = (type: 'error' | 'success' | 'info') => {
  return (content: string, title?: string) =>
    toast.add({
      severity: type,
      summary: title,
      detail: content,
      life: 3000,
      group: 'tc',
    })
}

window._message = {
  error: messageFn('error'),
  success: messageFn('success'),
  info: messageFn('info'),
}

let dialogInstances: DynamicDialogInstance[] = []
window.addEventListener('keydown', evt => {
  if (evt.code === 'Escape') {
    if (dialogInstances.length > 0) {
      const currentDialog = dialogInstances[dialogInstances.length - 1]
      if (currentDialog.data.closeOnEscape) {
        currentDialog.close()
      }
    }
  }
})
// PC 上自己处理 dialog 的点击蒙层关闭事件
// 如果点击的是 dialog 内部且不松手移动到 mask 再松手时仍会触发 click 事件
// 所以这里使用 mousedown 事件
window.addEventListener('mousedown', evt => {
  if (
    (evt.target as HTMLDivElement).classList.contains('p-dialog-mask') &&
    dialogInstances.length > 0
  ) {
    const currentDialog = dialogInstances[dialogInstances.length - 1]!
    if (currentDialog.data.dismissableMask) {
      currentDialog.close()
    }
  }
})

const dialog = useDialog()

window._openDialog = function <R>(
  Comp: Component,
  options: {
    title?: string | Ref<string>
    props?: any
    dialog?: DialogProps
    rootClass?: string
  } = {}
): OpenDialogPromise<R> {
  const { title = '', props = {}, rootClass = '' } = options

  let instance: DynamicDialogInstance

  const titleRef = typeof title === 'string' ? ref(title) : title

  const dialogOptions = options.dialog || {}
  if (!dialogOptions.pt) {
    dialogOptions.pt = {}
  }
  if (!dialogOptions.pt.root) {
    dialogOptions.pt.root = {}
  }
  dialogOptions.pt.root.class = rootClass
  dialogOptions.pt.mask = {
    class: dialogOptions.pt.mask?.class,
    style: `z-index: ${zindexMgmt.nextDialogZIndex()}`,
  }

  const result = new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h(Comp, {
          ...props,
          onDone: (val: R) => {
            resolve(val)
            instance.close()
          },
        })
      },
      {
        data: {
          dismissableMask: dialogOptions.dismissableMask ?? true,
          closeOnEscape: dialogOptions.closeOnEscape ?? true,
        },
        props: {
          ...dialogOptions,
          modal: true,
          draggable: false,
          // esc 关闭我们自己实现，默认的实现会关闭全部 dialog
          closeOnEscape: false,
          closable: true,
          dismissableMask: false,
          autoZIndex: false,
        },
        templates: {
          header: () =>
            h(
              'h2',
              {
                class: 'text-xl font-medium',
              },
              titleRef.value
            ),
        },
        onClose() {
          dialogInstances = dialogInstances.filter(i => i !== instance)
          resolve(undefined)
        },
      }
    )

    dialogInstances.push(instance)
  }) as OpenDialogPromise<R>

  result.instance = dialogInstances[dialogInstances.length - 1]
  result.close = () => instance.close()

  return result
}

window._confirm = function (options): Promise<undefined> {
  return _openDialog(Confirm, {
    rootClass: 'g-dialog',
    props: {
      scene: options.scene,
      icon: options.icon,
      title: options.title,
      content: options.content,
      primaryText: options.primaryText,
      primaryIcon: options.primaryIcon,
      secondaryText: options.secondaryText,
      onPrimaryClick(cb: ConfirmResolveCallback) {
        // 如果按钮没有设置回调，则点击时直接关闭弹窗
        if (options.onPrimaryClick == null) {
          cb(true)
        } else {
          options.onPrimaryClick(cb)
        }
      },

      onSecondaryClick(cb: ConfirmResolveCallback) {
        if (options.onSecondaryClick == null) {
          cb(true)
        } else {
          options.onSecondaryClick(cb)
        }
      },
    },
    dialog: {
      showHeader: false,
      dismissableMask: false,
      closeOnEscape: false,

      pt: {
        content: {
          class: `m-4 rounded-none`,
          style: 'padding: 0px',
        },
      },
    },
  })
}

window._notice = function (options): Promise<undefined> {
  const { onConfirm } = options

  return _openDialog(Notice, {
    props: {
      scene: options.scene,
      content: options.content,
      okText: options.okText,
      onConfirm,
    },
    dialog: {
      showHeader: false,
      contentStyle: 'padding-top: 0px;',
    },
  })
}

window._openInputDialog = function (options: InputDialogOptions) {
  let instance: DynamicDialogInstance

  const { onSubmit, placeholder, okText } = options
  const text = ref(options.text ?? '')
  const loading = ref(false)

  return new Promise<string | undefined>(resolve => {
    instance = dialog.open(
      () => {
        return h('div', [
          h(InputText, {
            class: 'w-full mb-4',
            modelValue: text.value,
            placeholder: placeholder,
            'onUpdate:modelValue': (val: string) => {
              text.value = val
            },
          }),
          h(Button, {
            class: 'w-full',
            label: okText,
            onClick: () => {
              loading.value = false

              onSubmit(text.value)
                .then((close: boolean) => {
                  if (close) {
                    resolve(text.value)
                    instance.close()
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            },
          }),
        ])
      },
      {
        props: {
          header: options.title,
          modal: true,
          draggable: false,
        },
        onClose() {
          resolve(undefined)
          dialogInstances = dialogInstances.filter(i => i !== instance)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._presentContent = function (
  Comp: Component,
  options?: {
    props?: any
    rootClass?: string
    contentClass?: string
    dialog?: DialogProps
  }
) {
  return _openDialog(Comp, {
    props: options?.props,
    rootClass: options?.rootClass,
    dialog: {
      ...options?.dialog,
      showHeader: false,
      dismissableMask: options?.dialog?.dismissableMask ?? true,
      closeOnEscape: options?.dialog?.closeOnEscape,
      pt: {
        ...options?.dialog?.pt,
        root: (options?.dialog?.pt as any)?.root,
      },
    },
  })
}

window._showRewards = function (rewards: Reward[]) {
  _openDialog(RewardDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-4' },
      },
    },
    props: {
      rewards,
    },
  })
}

let debugPanelOpen = false
window._openDebugPanel = function () {
  if (_global.isProd) return

  if (debugPanelOpen) {
    return
  }

  debugPanelOpen = true
  import('@/components/DebugPanel/DebugPanel.vue').then(mod => {
    _openDialog(mod.default, { title: '调试面板' })
      .then(() => {
        debugPanelOpen = false
      })
      .catch(() => {
        location.reload()
      })
  })
}

window._openDiamondGetDialog = function (): Promise<void> {
  return _presentContent(NotEnoughDiamonds, {
    rootClass: 'min-w-400px max-w-600px',
    dialog: {
      contentStyle: 'padding: 0px;',
    },
  })
}

window._closeAllDialogs = function () {
  dialogInstances.forEach(i => i.shouldIgnoreByCloseAll || i.close())
  dialogInstances = []
}

window._closeActiveDialog = function () {
  const active = dialogInstances.pop()
  active?.close()
}

// mount DebugPanel
if (!_global.isProd) {
  useHotKey('f1', window._openDebugPanel)
}
</script>

<style></style>
